<script>
import HeaderView from '../../../shared/views/HeaderView';
import MainMenuView from '../../../shared/views/MainMenuView';
import ListNotificationsView from '../../views/lists/ListNotificationsView';

export default {
  components: {
    'header-view': HeaderView,
    'main-menu-view': MainMenuView,
    'list-notifications-view': ListNotificationsView,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>
<template>
  <div>
    <header-view></header-view>
    <main-menu-view></main-menu-view>
    <!-- Body Start -->
    <list-notifications-view></list-notifications-view>
    <!-- Body End -->
  </div>
</template>