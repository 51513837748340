<script>
import FacebookLoginComponent from '../features/FacebookLoginComponent';

export default {
  name: 'facebookLogin',
  mixins: [FacebookLoginComponent],
  components: {},
};
</script>

<template>
  <div>
    <button class="social_lnk_btn color_btn_fb" @click="logInWithFacebook"><i class="uil uil-facebook-f"></i>Registrarse con Facebook</button>
  </div>
</template>