import BaseApi from '../../../infraestructure/BaseApi';

export default class ApiSubscriptions extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    findByAccount(){
        return this.client.get(`${this.endPoint}/by-account`)
    }

    doSubscription(data){
        return this.client.post(`${this.endPoint}/do-subscription`, data)
    }
}