import BaseComponent from '../../../core/infraestructure/BaseComponent';
import PasswordResetViewModel from '../../../core/features/authentication/viewmodels/PasswordResetViewModel.js';

export default {
    extends: BaseComponent,
    data() {
        const validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('Favor de ingresar información.'));
            } else if (value !== this.formData.password) {
                callback(new Error('Las contraseñas no coinciden.'));
            } else {
                callback();
            }
        };
        return {
            viewModel: null,
            formProcessingMessage: "",
            isFormProcessing: false,
            formData: {
                password: '',
                checkPass: ''
            },
            rules: {
                password: [
                    { required: this.isPasswordRequired(), message: 'Contraseña requerida.' },
                    { min: 4, message: 'Se requieren más de 4 caracteres', trigger: 'blur,change', },
                    { type: 'string', pattern: /^[0-9a-zA-ZñÑ@._-]+$/g, message: 'Valores alfabeticos y (@,._-)', trigger: 'change' }
                ],
                checkPass: [
                    { required: this.isPasswordRequired(), message: 'Verifiación de contraseña requerida.' },
                    { min: 4, message: 'Se requieren más de 4 caracteres', trigger: 'blur,change', },
                    { type: 'string', pattern: /^[0-9a-zA-ZñÑ@._-]+$/g, message: 'Valores alfabeticos y (@,._-)', trigger: 'change' },
                    { validator: validatePass },
                ]
            }
        };
    },
    created() {
        this.viewModel = new PasswordResetViewModel(this);
        this.onActive();
    },
    methods: {
        onActive() {
            var token = this.viewModel.getUrlParameter('token');
            if (token) {
                this.formData.token = token
            } else {
                this.$router.push('/')
            }
        },
        onSend(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.viewModel.showFormLoading(true, 'Reiniciando cuenta...')
                    this.viewModel.onPasswordReset(this.formData)
                    return true;
                }
                return false;
            });
        },
        isPasswordRequired() { return this.updatePassword },
        onPasswordResetResponse(response) {
            this.viewModel.showFormLoading(false, '')
            this.viewModel.showNotification('success', 'Completo', response.message)

            setTimeout(() => {
                this.$router.push("/");
            }, 2000);
        },
        onPasswordResetError(response) {
            this.viewModel.showFormLoading(false, '')
            this.onError(response);
        }

    },
    computed: {}
};