import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ListComponent from '../../../../core/infraestructure/ListComponent';
import UserViewModel from '../../../../core/features/authorization/viewmodels/UserViewModel';

export default {
  extends: ListComponent,
  data() {
    return {
      modulePermissions: 'Authorization-Users',
      item: null,
      searchFullName: null,
      searchEmail: null,
      searchCode: null,
      viewModel: null,
      roles: [],
      userStatuses: [],
      filterOptions: {
        roleFilter: '',
        status: ''
      },
      modalImport: {
        isVisible: false,
        isLoading: false,
        title: 'IMPORTAR USUARIOS',
        onUploadImport: 'onUploadImportUser',
        onCancelImport: 'onCancelImportUser'
      }
    }
  },
  mounted() {
    EventBus.$on('onSubmitedUser', this.onSearch);
    EventBus.$on('onUploadImportUser', this.onUploadImport);
    EventBus.$on('onCancelImportUser', this.onCancelImport);
  },
  beforeDestroy() {
    EventBus.$off('onSubmitedUser', this.onSearch);
    EventBus.$off('onUploadImportUser', this.onUploadImport);
    EventBus.$off('onCancelImportUser', this.onCancelImport);
  },
  created() {
    this.viewModel = new UserViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.onSearch();
      this.onSearchUserStatus()
    },
    onAllFilterChange(data, field) {
      this.onFilterChange(data, field);
    },
    onSearchRole(data) {
      if (data) {
        this.viewModel.findRoles(`Name=${data}`)
      } else {
          this.parents = []
      }
    },
    onSearchRolesResponse(response) {
      this.roles = response;
    },
    onFocusRole() {
      this.$forceUpdate();
    },
    onSearchUserStatus() {
      this.viewModel.findUserStatuses()
    },
    onSearchUserStatusesResponse(response) {
      this.userStatuses = response;
    },
    onFocusUserStatus() {
      this.$forceUpdate();
    },
    onShowImport() {
      this.viewModel.showModal(this.modalImport, true);
    },
    onUploadImport(file){
      this.viewModel.showModalLoading(this.modalImport, true);
      this.viewModel.import(file);
    },
    onCancelImport(){
      this.viewModel.showModal(this.modalImport, false);
    },
    onExportToExcel() {
      this.viewModel.exportToExcel();
    },
    onCanCreate() {
      return this.canCreate(this.modulePermissions)
    },
    onCanRead() {
      return this.canRead(this.modulePermissions)
    },
    onCanUpdate() {
      return this.canUpdate(this.modulePermissions)
    },
    onCanDelete() {
      return this.canDelete(this.modulePermissions)
    },
  },
  computed: {}
}