export default class ResponseActivityAnswer {
    constructor(options) {
        Object.assign(this, options)
    }

    toSend() {
        return  {
            id: this.id,
            success: this.success,
            contentObservation: this.contentObservation
        }
    }
}