<script>
import LoginComponent from '../features/LoginComponent';
import FacebookLoginView from '../views/FacebookLoginView';

export default {
  name: 'LoginView',
  mixins: [LoginComponent],
  components: {
      'facebook-login-button': FacebookLoginView,
  },
};
</script>

<template>
    <!-- Signup Start -->
    <div class="sign_in_up_bg">
        <div class="container">
            <div class="row justify-content-lg-center justify-content-md-center">
                <div class="col-lg-12">
                    <div class="main_logo25" id="logo">
                        <router-link to="/"><img class="logo-login" src="images/logo.png" alt=""></router-link>
                        <router-link to="/"><img class="logo-inverse" src="images/ct_logo.svg" alt=""></router-link>
                    </div>
                </div>

                <div class="col-lg-6 col-md-8">
                    <div class="sign_form">
                        <h2>Bienvenido</h2>
                        <p>Ingresa con tu cuenta de Appmisionate!</p>
                        <!-- <facebook-login-button></facebook-login-button> -->
                        <!-- <button class="social_lnk_btn color_btn_fb"><i class="uil uil-facebook-f"></i>Continue with Facebook</button> -->
                        <!-- <button class="social_lnk_btn mt-15 color_btn_tw"><i class="uil uil-twitter"></i>Continue with Twitter</button>
                        <button class="social_lnk_btn mt-15 color_btn_go"><i class="uil uil-google"></i>Continue with Google</button> -->
                        <el-form :model="formLogin"
                                 :rules="rules"
                                 ref="formLogin"
                                 status-icon
                                 :label-position="'left'"
                                 v-loading="isFormProcessing"
                                 :element-loading-text="formProcessingMessage">
                            <div class="ui search focus mt-15">
                                <div class="ui left icon input swdh95">
                                    <el-form-item label="Usuario o Correo:" prop="Username">
                                        <el-input v-model="formLogin.Username"
                                                  auto-complete="off"
                                                  :value="formLogin.username"></el-input>
                                    </el-form-item>
                                    <i class="uil uil-envelope icon icon2"></i>
                                </div>
                            </div>
                            <div class="ui search focus mt-15">
                                <div class="ui left icon input swdh95">
                                    <el-form-item label="Contraseña:" prop="Password">
                                        <el-input v-model="formLogin.Password"
                                                  auto-complete="off"
                                                  :value="formLogin.password"
                                                  type="password"
                                                  @keyup.enter.native="onLogin('formLogin')"></el-input>
                                    </el-form-item>
                                    <i class="uil uil-key-skeleton-alt icon icon2"></i>
                                </div>
                            </div>
                            <div class="ui form mt-30 checkbox_sign">
                                <div class="inline field">
                                    <el-checkbox v-model="formLogin.rememberMe">Recordarme</el-checkbox>
                                </div>
                            </div>
                            <button class="login-btn btn-primary" type="button" @click="onLogin('formLogin')">Ingresar</button>
                        </el-form>
                        <p class="sgntrm145">O <router-link class="link-primary" :to="'/recuperacion-password'"> Olvidaste tu contraseña</router-link>.</p>
                        <p class="mb-0 mt-30 hvsng145">No tienes una cuenta? <router-link class="link-primary" :to="'/registrarse'"> Registrarse</router-link></p>
                    </div>
                    <div class="sign_footer"><img src="/images/logo_no_text.png" alt="" class="logo_footer">© 2020 <strong>AppMisionate</strong>. Todos los Derechos Reservados.</div>
                </div>
            </div>
        </div>
    </div>
    <!-- Signup End -->
</template>
