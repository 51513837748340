<script>
import PageNotFoundComponent from '../features/PageNotFountComponent';
import HeaderView from './HeaderView';
import MainMenuView from './MainMenuView';

export default {
  name: 'PageNotFoundView',
  mixins: [PageNotFoundComponent],
  components: {
    'header-box-view': HeaderView,
    'main-menu-view': MainMenuView,
  },
};
</script>
<template>

<div class="coming_soon_style" style="height:100vh; margin-bottom:0px; position:absolute; width:100%;">
  <!-- Body Start -->
	<div class="wrapper coming_soon_wrapper">		
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="cmtk_group">
						<!-- <div class="ct-logo">
							<a href="index.html"><img src="images/ct_logo.svg" alt=""></a>
						</div> -->
						<div class="cmtk_dt">
							<h1 class="title_404">404</h1>
							<h4 class="thnk_title1">La pagina que estas buscando no pudo ser encontrada.</h4>
							<router-link :to="'/'" class="bk_btn btn-primary">Regresar</router-link>
						</div>
						<div class="tc_footer_main">
							<div class="tc_footer_left">
								<ul>
									<a href="/">Inicio</a>
									<a href="/acerca">Acerca</a>
									<a href="/noticias">Noticias</a>
									<a href="/contacto">Contacto</a>
									<a href="/soporte">Soporte</a>
									<a href="/terminos-y-politicas">Terminos y Pol&iacute;ticas</a>
									<a href="/mapa-del-sitio">Mapa del Sitio</a>
								</ul>						
							</div>
							<div class="tc_footer_right">
								<p>© 2021 <strong>AppMisionate</strong>. Todos los Derechos Reservados.</p>
							</div>
						</div>
					</div> 	
				</div>	
			</div>	
		</div>		
	</div>	
	<!-- Body End -->
</div>
</template>
