<script>
import ModalCourseCategoryComponent from '../../features/modals/ModalCourseCategoryComponent';
export default {
  extends: ModalCourseCategoryComponent,
  name: 'ModalCourseCategoryView',
};
</script>

<template>
  <el-dialog
    title="Categoria de Curso"
    :visible.sync="conf.isVisible"
    width="30%"
    custom-class="course-category-modal"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      status-icon
      :label-position="'top'"
      v-loading="conf.isLoading"
    >
      <fieldset>
        <legend>Información de Categoria</legend>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Categoria Padre">
              <el-select v-model="formData.parent" clearable filterable remote :remote-method="onSearchCourseCategory" placeholder="Seleccionar" value-key="id" size="small">
                <el-option v-for="item in parents" :key="item.id" :label="item.name" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
         <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Código" prop="code">
              <el-input v-model="formData.code" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
         <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Nombre" prop="name">
              <el-input v-model="formData.name" maxlength="70" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Descripción" prop="description">
              <el-input v-model="formData.description" maxlength="155" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </fieldset>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel" size="small" class="btn-cancel"
        >Cancelar</el-button
      >
      <el-button
        type="primary"
        @click="onSubmitForm('formData')"
        :disabled="!onCanSaveOrUpdate()"
        class="btn-primary"
        size="small"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>