import Timer from 'easytimer.js';

export default {
    startCountDown(options) {
        var timer = new Timer();

        timer.start({countdown: true, startValues: options.startValues });
        
        options.onStart(timer.getTimeValues().toString());

        timer.addEventListener('secondsUpdated', (e) => {
            options.onUpdate(timer.getTimeValues().toString())
        });

        timer.addEventListener('targetAchieved', (e) => {
            options.onFinished()
        });
        
        return timer;
    }
}