








// import HelloWorld from '@/components/HelloWorld.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    // HelloWorld
  },
})
export default class App extends Vue {}
