<script>
import HeaderView from '../../../shared/views/HeaderView';
import MainMenuStudentView from '../../../shared/views/MainMenuStudentView';
import MainFooterView from '../../../shared/views/MainFooterView';
import ListStudentsNotificationsView from '../../views/lists/ListStudentsNotificationsView';

export default {
  components: {
    'header-view': HeaderView,
    'main-menu-view': MainMenuStudentView,
    'list-students-notifications-view': ListStudentsNotificationsView,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>
<template>
  <div>
    <header-view></header-view>
    <main-menu-view></main-menu-view>
    <list-students-notifications-view></list-students-notifications-view>
  </div>
</template>