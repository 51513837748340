<script>
import ModalNotificationAnswerActivityComponent from '../../features/Modals/ModalNotificationAnswerActivityComponent';

export default {
  extends: ModalNotificationAnswerActivityComponent,
  name: 'ModalNotificationAnswerActivityView',
  components: {},
};
</script>

<template>
  <div>
    <el-dialog
      title="Revisión de Actividad"
      :visible.sync="conf.isVisible"
      width="50%"
      :before-close="onCancel"
      :close-on-click-modal="false"
    >
      <el-form
        :model="formData"
        :rules="rules"
        ref="formData"
        status-icon
        :label-position="'top'"
        v-loading="conf.isLoading"
      >
        <fieldset>
          <legend>Actividad</legend>
          <br />
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24">
              <div v-html="formData.courseSubjectActivity.title"></div>
            </el-col>
          </el-row>
          <br />
        </fieldset>
        <fieldset>
          <legend>Descripci&oacute;n</legend>
          <br />
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24">
              <div v-html="formData.courseSubjectActivity.description"></div>
            </el-col>
          </el-row>
          <br />
        </fieldset>
        <fieldset>
          <legend>Respuesta</legend>
          <br />
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24">
              <div v-html="formData.contentResponse"></div>
            </el-col>
          </el-row>
          <br />
          
        </fieldset>
        <fieldset>
          <legend>Observaci&oacute;n</legend>
          <br />
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24">
              <el-form-item prop="success">
                <el-switch
                  style="display: block"
                  v-model="formData.success"
                  active-color="#13ce66"
                  inactive-text="Incorrecto"
                  active-text="Correcto"
                  :inactive-value="false"
                  :active-value="true"
                  :disabled="true"
                ></el-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <br />
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24">
              <div v-html="formData.contentObservation"></div>
            </el-col>
          </el-row>
        </fieldset>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel" size="small" class="btn-cancel"
          >Aceptar</el-button
        >
        <!-- <el-button
          type="primary"
          @click="onSubmitForm('formData')"
          :disabled="!onCanSaveOrUpdate()"
          class="btn-primary"
          size="small"
          >Guardar</el-button
        >-->
      </span>
    </el-dialog>
  </div>
</template>
