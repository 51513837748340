import _ from 'lodash'
import BaseViewModel from '../../../infraestructure/BaseViewModel'

export default class PasswordRecoveryViewModel extends BaseViewModel {
  constructor (view) {
    super(view)
  }

  onPasswordRecovery (data) {
    this.api.authentication.recoveryPassword(data)
      .then(response => this.view.onPasswordRecoveryResponse(response.data))
      .catch(this.view.onPasswordRecoveryError)
  }
  
}