import EndPoints from '../../../infraestructure/EndPoints'
const endPoint = `${EndPoints.API}${EndPoints.AUTH}`

export default class ApiAuthentication {

    constructor(client) {
        this.client = client
    }

    login(data) {
        return this.client.post(`${endPoint}/login`, data)
    }

    loginFacebook(data) {
        return this.client.post(`${endPoint}/login-facebook`, data)
    }

    logout() {
        return this.client.post(`${endPoint}/logout`)
    }

    register(data) {
        return this.client.post(`${endPoint}/register`, data)
    }

    confirmAccount(data) {
        return this.client.post(`${endPoint}/confirmation-account`, data)
    }

    recoveryPassword (data) {
        return this.client.post(`${endPoint}/recovery-password`, data)
    }

    resetPassword (data) {
        return this.client.post(`${endPoint}/reset-password`, data)
    }
    
}