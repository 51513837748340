export default class Subscription {
    constructor(options) {
      Object.assign(this, options)
    }
  
    getFullName(){
      return `${this.firstName} ${this.lastName}`
    }
  
    getPermissions(){
  
    }
  
    toSend() {
      return {
        id: this.id,
        userProfileId: this.userProfile.id,
        subscriptionDate: this.reportDate[0],
        subscriptionEndDate: this.reportDate[1],
        discountId: this.discount.id,
        subscriptionPlanId: this.subscriptionPlan.id,
        amount: this.amount,
      }
    }
  }
  