import BaseApi from '../../../infraestructure/BaseApi';

export default class ApiDashboard extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    findCourseStats() {
        return this.client.get(`${this.endPoint}/course-stats`)
    }

    findUserStats() {
        return this.client.get(`${this.endPoint}/user-stats`)
    }
}