<script>
import ModalEvaluationComponent from '../../features/modals/ModalEvaluationComponent';
export default {
  extends: ModalEvaluationComponent,
  name: 'ModalEvaluationView',
};
</script>

<template>
  <el-dialog
    title="Evaluación de Curso"
    :visible.sync="conf.isVisible"
    width="30%"
    custom-class="evaluation-modal"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      status-icon
      :label-position="'top'"
      v-loading="conf.isLoading"
    >
      <fieldset>
        <legend>Información de Evaluación</legend>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Estudiante" prop="student">
              <el-select v-model="formData.student" clearable filterable remote :remote-method="onSearchStudent" placeholder="Seleccionar" value-key="id" size="small">
                <el-option v-for="item in students" :key="item.id" :label="item.userProfile.firstName +' '+ item.userProfile.lastName" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Curso" prop="course">
              <el-select v-model="formData.course" clearable filterable remote :remote-method="onSearchCourse" placeholder="Seleccionar" value-key="id" size="small">
                <el-option v-for="item in courses" :key="item.id" :label="item.title" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Calificación" prop="qualification" style="text-align: center;">
              <el-rate v-model="formData.qualification"></el-rate>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Obervaciones" prop="observation">
              <el-input v-model="formData.observation" type="textarea" :rows="5" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </fieldset>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel" size="small" class="btn-cancel"
        >Cancelar</el-button
      >
      <el-button
        type="primary"
        @click="onSubmitForm('formData')"
        :disabled="!onCanSaveOrUpdate()"
        class="btn-primary"
        size="small"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>