import BaseComponent from '../../../core/infraestructure/BaseComponent'

export default {
  extends: BaseComponent,
  data() {
    return {};
  },
  created() { },
  mounted() { },
  methods: {}
};