export default class Instructor {
    constructor(options) {
      Object.assign(this, options)
    }

    getFullName() {
      return `${this.userProfile.firstName} ${this.userProfile.lastName}`
    }
  
    toSend() {
      return {
        instructorId: this.id,
        userProfile: this.mapUserProfile(this.userProfile),
        educationSpecialty: this.educationSpecialty,
        pageUrlFacebook: this.pageUrlFacebook,
        pageUrlLinkedIn: this.pageUrlLinkedIn,
        pageUrlInstagram: this.pageUrlInstagram,
        homePhone: this.homePhone,
        cellPhone: this.cellPhone,
        diseaseOrSpecialCondition: this.diseaseOrSpecialCondition,
        homeAddress: this.homeAddress,
        about: this.about,
        selectedCareerObservation: this.selectedCareerObservation,
        advisoryObservation: this.advisoryObservation,
        subjectComplicationObservation: this.subjectComplicationObservation,
      }
    }

    mapUserProfile(data) {
      return {
        userId: this.id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        status: data.status,
        userName: data.userName,
        password: data.password,
        roleId: (data.role) ? data.role.id : '',
      }
    }
  }