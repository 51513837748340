import { EventBus } from '../../../core/shared/helpers/EventHelper';
import BaseComponent from '../../../core/infraestructure/BaseComponent'

export default {
    extends: BaseComponent,
    data() {
        return {
            formData: null,
            rules: {}
        };
    },
    props: {
        conf: {
            type: Object,
            required: true
        }
    },
    methods: {
        onUpload(file) {
            EventBus.$emit(this.conf.onUploadImport, file);
        },
        onCancel(){
            EventBus.$emit(this.conf.onCancelImport, null);
        }
    }
}