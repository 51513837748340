import BaseApi from '../../../infraestructure/BaseApi';

export default class ApiNotifications extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    doMarkAsRead(notificationId) {
        return this.client.put(`${this.endPoint}/${notificationId}/mark-as-read`)
    }

    findAlerts() {
        return this.client.get(`${this.endPoint}/alerts`)
    }

    findByAccount(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/by-account${parameters}`)
    }
}