import BaseComponent from "../../../../core/infraestructure/BaseComponent";
import DashboardInstructorViewModel from '../../../../core/features/home/viewmodels/DashboardInstructorViewModel'

export default {
    extends: BaseComponent,
    data() {
        return {
            viewModel: null,
            formProcessingMessage: "",
            isFormProcessing: false,
            currentStats: null,
            formData: {
                email: ''
            },
            rules: {
                /* email: [
                    { required: true, message: 'Correo electrónico requerido.', trigger: 'blur,change' },
                    { type: 'email', message: 'Correo electrónico no valido.', trigger: 'blur,change' }
                ], */
            }
        };
    },
    created() {
        this.viewModel = new DashboardInstructorViewModel(this);
        this.onActive();
    },
    methods: {
        onActive() {
            this.viewModel.bindStats()
        },
        onFindStatsResponse(response){
            this.currentStats = response.data;
        },
    },
    computed: {}
}