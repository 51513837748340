import BaseApi from '../../../infraestructure/BaseApi';

export default class ApiResponseActivityAnswers extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    doReview(id, data) {
        return this.client.post(`${this.endPoint}/${id}/do-review`, data)
    }
}