<script>
import ModalStreamLiveClassComponent from '../../features/modals/ModalStreamLiveClassComponent';
export default {
  extends: ModalStreamLiveClassComponent,
  name: 'ModalStreamLiveClassView',
};
</script>

<template>
  <el-dialog
    title="En Vivo"
    :visible.sync="conf.isVisible"
    width="42%"
    custom-class="live-class-stream-modal"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <el-row v-if="formData">
      <el-col :span="24" align="center">
        <iframe
          v-if="formData.streamUrl"
          :src="formData.streamUrl"
          width="750"
          height="450"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        >
        </iframe>
      </el-col>
    </el-row>
  </el-dialog>
</template>