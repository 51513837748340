<script>
import EvaluationComponent from '../../features/details/EvaluationComponent';
export default {
  extends: EvaluationComponent,
  name: 'EvaluationComponent',
};
</script>

<template>
  <el-dialog
    title="Evaluación de Curso"
    :visible.sync="conf.isVisible"
    width="20%"
    custom-class="evaluation-modal"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      status-icon
      :label-position="'top'"
      v-loading="conf.isLoading"
    >
      <fieldset>
        <legend>Información de Evaluación</legend>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Calificación" prop="qualification" style="text-align: center;">
              <el-rate v-model="formData.qualification"></el-rate>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Obervaciones" prop="observation">
              <el-input v-model="formData.observation" type="textarea" :rows="5" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </fieldset>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel" size="small" class="btn-cancel"
        >Cancelar</el-button
      >
      <el-button
        type="primary"
        @click="onSubmitForm('formData')"
        class="btn-primary"
        size="small"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>