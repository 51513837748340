<script>
import HeaderView from '../../../shared/views/HeaderView';
import MainMenuView from '../../../shared/views/MainMenuView';
import MainFooterView from '../../../shared/views/MainFooterView';
import ListAdvisoryCitiesView from '../lists/ListAdvisoryCitiesView';
import PermissionHelper from '../../../../core/shared/helpers/PermissionHelper';

export default {
  components: {
    'header-view': HeaderView,
    'main-menu-view': MainMenuView,
    'footer-view': MainFooterView,
    'list-advisory-cities-view': ListAdvisoryCitiesView,
  },
  data() {
    return {};
  },
  methods: {
    onCanShow(moduleName) {
      return PermissionHelper.canShow(moduleName);
    },
  },
};
</script>

<template>
  <div>
    <header-view></header-view>
    <main-menu-view></main-menu-view>
      <div class="wrapper">
      <div class="sa4d25">
        <div class="container-fluid">
            <div class="row">
              <div class="col-xl-12 col-lg-12">
                <div class="section3125">
                  <h4 class="item_title"><i class="fa fa-cogs"></i> Ciudades de Asesoría</h4>
                  <div class="la5lo1">
                   <list-advisory-cities-view></list-advisory-cities-view>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <footer-view></footer-view>
    </div>
  </div>
</template>