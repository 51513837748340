<script>
import MainFooterView from '../../../shared/views/MainFooterView';
import ListNotificationsByAccountComponent from '../../features/Lists/ListNotificationsByAccountComponent';
import ModalNotificationAnswerActivity from '../modals/ModalNotificationAnswerActivityView';
import ModalStudentInfoView from '../../../account/views/ModalStudentInfoView';

export default {
  extends: ListNotificationsByAccountComponent,
  components: {
    'footer-view': MainFooterView,
    'modal-notification-answer-activity-view': ModalNotificationAnswerActivity,
    'modal-student-info-view': ModalStudentInfoView,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>
<template>
  <!-- Body Start -->
  <div class="wrapper">
    <div class="sa4d25">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12">
            <h2 class="st_title">
              <i class="uil uil-bell"></i> Todas las notificaciones
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <!-- <a href="#" class="setting_noti">Notification Setting</a> -->
            <div class="all_msg_bg">
              <div
                class="channel_my item all__noti5"
                v-for="notification in collection"
                :key="notification.id"
              >
                <div class="profile_link">
                  <img :src="mapImage(notification.sender)" alt="" />
                  <div class="pd_content">
                    <h6>{{ notification.header }}</h6>
                    <p class="noti__text5">
                      {{ notification.message }}.
                      <strong v-if="notification.url">
                        <el-button type="text" @click="onSelectNotification(notification)"
                          class="notification-view-more">Mostrar...</el-button
                        ></strong>
                    </p>
                    <el-tag size="small" type="default" effect="plain" v-if="!notification.isRead">Nuevo</el-tag>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isCollectionEmpty(collection)"><h3>No se encontraron notificaciones</h3></div>
        <el-pagination
          v-else
          small
          layout="prev, pager, next"
          :page-count="pagination.totalPages"
          :current-page.sync="currentPage"
          @current-change="onPageChange"
        ></el-pagination>
      </div>
    </div>
    <modal-notification-answer-activity-view
      :formData="itemAnswerActivity"
      :conf="modalItemAnswerActivity"
      v-if="modalItemAnswerActivity.isVisible"
    ></modal-notification-answer-activity-view>
    <modal-student-info-view
    :formData="studentInfoItem"
    :conf="modalSubscriptionInfo"
    v-if="modalSubscriptionInfo.isVisible"></modal-student-info-view>

    <footer-view></footer-view>
  </div>
  <!-- Body End -->
</template>
