import BaseComponent from "../../../core/infraestructure/BaseComponent";
import PasswordRecoveryViewModel from '../../../core/features/authentication/viewmodels/PasswordRecoveryViewModel.js';

export default {
    extends: BaseComponent,
    data() {
        return {
            viewModel: null,
            formProcessingMessage: "",
            isFormProcessing: false,
            formData: {
                email: ''
            },
            rules: {
                email: [
                    { required: true, message: 'Correo electrónico requerido.', trigger: 'blur,change' },
                    { type: 'email', message: 'Correo electrónico no valido.', trigger: 'blur,change' }
                ],
            }
        };
    },
    created() {
        this.viewModel = new PasswordRecoveryViewModel(this);
    },
    methods: {
        onSend(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.viewModel.showFormLoading(true, 'Buscando Cuenta de Usuario...');
                    this.viewModel.onPasswordRecovery(this.formData);
                    return true;
                }
                return false;
            });
        },
        onPasswordRecoveryResponse(response) {
            this.viewModel.showFormLoading(false, '')
            this.viewModel.showNotification('success', 'Completo', response.message);

            setTimeout(() => {
                this.$router.push("/");
            }, 2000);
        },
        onPasswordRecoveryError(error) {
            this.viewModel.showFormLoading(false, '');
            this.onError(error);
        }

    },
    computed: {}
};