<script>
import HomeInstructorDashboardComponent from '../../features/dashboards/HomeInstructorDashboardComponent';

export default {
  extends: HomeInstructorDashboardComponent,
  name: 'HomeInstructorDashboardView',
  components: {},
};
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <h5 class="st_title">Estadísticas De Alumnos En Tus Cursos</h5>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3" v-if="currentStats">
        <div class="card_dash">
          <div class="card_dash_left">
            <h5>Cursos Favoritos</h5>
            <h2>{{ currentStats.totalFavorite }}</h2>
            <!-- <span class="crdbg_3">New 5</span> -->
          </div>
          <div class="card_dash_right">
            <img src="/images/dashboard/online-course.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3" v-if="currentStats">
        <div class="card_dash">
          <div class="card_dash_left">
            <h5>Cursos Que Gustan</h5>
            <h2>{{ currentStats.totalLiked }}</h2>
            <!-- span class="crdbg_3">New 5</span> -->
          </div>
          <div class="card_dash_right">
            <img src="/images/dashboard/online-course.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3" v-if="currentStats">
        <div class="card_dash">
          <div class="card_dash_left">
            <h5>Cursos Que Desagradan</h5>
            <h2>{{ currentStats.totalDisliked }}</h2>
            <!-- <span class="crdbg_4">New 3</span> -->
          </div>
          <div class="card_dash_right">
            <img src="/images/dashboard/online-course.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3" v-if="currentStats">
        <div class="card_dash">
          <div class="card_dash_left">
            <h5>Cursos Finalizados</h5>
            <h2>{{ currentStats.totalFinished }}</h2>
            <!-- <span class="crdbg_4">New 3</span> -->
          </div>
          <div class="card_dash_right">
            <img src="/images/dashboard/online-course.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
