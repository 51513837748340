<script>
import HeaderView from '../../../shared/views/HeaderView';
import MainFooterViewVue from '../../../shared/views/MainFooterView.vue';
import MainMenuStudentView from '../../../shared/views/MainMenuStudentView';
import HomeStudentDashboardView from '../dashboards/HomeStudentDashboardView';

export default {
  components: {
    'header-view': HeaderView,
    'footer-view': MainFooterViewVue,
    'main-menu-student-view': MainMenuStudentView,
    'home-student-dashboard-view': HomeStudentDashboardView,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<template>
<div>
    <header-view></header-view>
    <main-menu-student-view></main-menu-student-view>
    <div class="wrapper">
      <div class="sa4d25">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <div class="section3125">
                <h4 class="item_title"><i class="uil uil-apps"></i> Resumen</h4>
                <div class="la5lo1">
                  <home-student-dashboard-view></home-student-dashboard-view>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer-view></footer-view>
    </div>
  </div>
</template>