import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import ContactPageViewModel from '../../../../core/features/website/viewmodels/ContactPageViewModel';

export default {
    data() {
      return {
          iconUrl: '/images/logo_no_text.png',
          center: [32.65117909984373, -115.4173479725479],
          item: null,
          viewModel: null,
      }
    },
    mounted() {},
    beforeDestroy() {},
    created() {
      this.viewModel = new ContactPageViewModel(this);
    },
    methods: {
        onActive() {},
        setUpMap() {
            this.viewModel.createMapContactMexicali(this.iconUrl, this.center);
        }
    },
    mounted() {
        this.setUpMap();
    },
    computed: {}
  }