import EndPoints from '../../../infraestructure/EndPoints'
const endPoint = `${EndPoints.API}${EndPoints.ROLES}`

export default class ApiRoles {
    constructor(client) {
        this.client = client
    }

    create(data) {
        return this.client.post(endPoint, data)
    }

    update(id, data) {
        return this.client.put(`${endPoint}/${id}`, data)
    }

    delete(id) {
        return this.client.delete(`${endPoint}/${id}`)
    }

    find(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${endPoint}${parameters}`)
    }

    findOne(id) {
        return this.client.get(`${endPoint}/${id}`)
    }

    verifyName(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${endPoint}/verify-role-name${parameters}`)
    }
}