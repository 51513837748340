<script>
import HeaderComponent from '../features/HeaderComponent';
import SessionBoxView from '../views/SessionBoxView';
import NotificationAlertsView from '../views/NotificationAlertsView';

export default {
  name: 'HeaderView',
  mixins: [HeaderComponent],
  components: {
    'session-box-view': SessionBoxView,
    'notification-alerts-view': NotificationAlertsView,
  },
};
</script>

<template>
  <!-- Header Start -->
  <header class="header clearfix">
    <button type="button" id="toggleMenu" class="toggle_menu">
      <i class="uil uil-bars"></i>
    </button>
    <button id="collapse_menu" class="collapse_menu">
      <i class="uil uil-bars collapse_menu--icon "></i>
      <span class="collapse_menu--label"></span>
    </button>
    <div class="main_logo" id="logo">
      <router-link to="/"
        ><img class="logo" src="/images/logo.png" alt=""
      /></router-link>
    </div>
    <!-- <div class="top-category">
			<div class="ui compact menu cate-dpdwn">
				<div class="ui simple dropdown item">
					<a href="#" class="option_links p-0" title="categories"><i class="uil uil-apps"></i></a>
					<div class="menu dropdown_category5">
						<a href="#" class="item channel_item">Matematicas</a>
						<a href="#" class="item channel_item">Finanzas</a>
						<a href="#" class="item channel_item">Historia</a>
						<a href="#" class="item channel_item">Calculo</a>
						<a href="#" class="item channel_item">Lectura y Redacción</a>
					</div>
				</div>
			</div>
		</div> -->
    <!-- <div class="search120">
			<div class="ui search">
			  <div class="ui left icon input swdh10">
				<input class="prompt srch10" type="text" placeholder="Search for Tuts Videos, Tutors, Tests and more..">
				<i class='uil uil-search-alt icon icon1'></i>
			  </div>
			</div>
		</div>-->
    <div class="header_right">
      <ul>
        <!-- <li>
					<a href="create_new_course.html" class="upload_btn" title="Create New Course">Create New Course</a>
				</li>
				<li>
					<a href="shopping_cart.html" class="option_links" title="cart"><i class='uil uil-shopping-cart-alt'></i><span class="noti_count">2</span></a>
				</li> -->
        <!-- <li class="ui dropdown">
					<a href="#" class="option_links" title="Messages"><i class='uil uil-envelope-alt'></i><span class="noti_count">3</span></a>
					<div class="menu dropdown_ms">
						<a href="#" class="channel_my item">
							<div class="profile_link">
								<img src="/images/left-imgs/img-6.jpg" alt="">
								<div class="pd_content">
									<h6>Zoena Singh</h6>
									<p>Hi! Sir, How are you. I ask you one thing please explain it this video price.</p>
									<span class="nm_time">2 min ago</span>
								</div>							
							</div>							
						</a>
						<a href="#" class="channel_my item">
							<div class="profile_link">
								<img src="/images/left-imgs/img-5.jpg" alt="">
								<div class="pd_content">
									<h6>Joy Dua</h6>
									<p>Hello, I paid you video tutorial but did not play error 404.</p>
									<span class="nm_time">10 min ago</span>
								</div>							
							</div>							
						</a>
						<a href="#" class="channel_my item">
							<div class="profile_link">
								<img src="/images/left-imgs/img-8.jpg" alt="">
								<div class="pd_content">
									<h6>Jass</h6>
									<p>Thanks Sir, Such a nice video.</p>
									<span class="nm_time">25 min ago</span>
								</div>							
							</div>							
						</a>
						<a class="vbm_btn" href="instructor_messages.html">View All <i class='uil uil-arrow-right'></i></a>
					</div>
				</li> -->
		<li v-if="showProgress">
			<span class="course-progress">Progreso</span> <el-progress type="circle" :color="courseProgressColors" :percentage="progressPercentage" :width="45"></el-progress>
		</li>
        <notification-alerts-view></notification-alerts-view>
        <session-box-view></session-box-view>
      </ul>
    </div>
  </header>
  <!-- Header End -->
</template>
