import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';
import EvaluationViewModel from '../../../../core/features/evaluations/viewmodels/EvaluationViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'ReviewsContent-Reviews',
            courses: [],
            students: [],
            viewModel: null,
            permissions: null,
            modules: [],
            rules: {
                qualification: [
                    { required: true, message: 'Calificación requerida.', trigger: 'blur' }
                ],
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new EvaluationViewModel(this)
        this.onActive()
    },
    mounted() { },
    methods: {
        onActive() { },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // this.onSave(this.formData)
                    // EventBus.$emit('onSubmitedEvaluation', this.formData);
                    this.$root.$emit('onSubmitedEvaluation', this.formData)
                }
            });
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onSaveResponse(response) {
            this.showLoading(false, '');
            this.onShowSuccess(response.message);
            this.showComponent(false);
            EventBus.$emit('onSubmitedEvaluation', null);
        }
    }
}