<script>
import ModalCoursePromotionalVideoComponent from '../../features/modals/ModalCoursePromotionalVideoComponent';
export default {
  extends: ModalCoursePromotionalVideoComponent,
  name: 'ModalCoursePromotionalVideoView',
};
</script>

<template>
  <el-dialog
    title="Video Promocional"
    :visible.sync="conf.isVisible"
    width="42%"
    custom-class="course-promotional-video-modal"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <el-row v-if="formData">
      <el-col :span="24" align="center">
        <iframe
          v-if="formData.promotionalVideoUrl"
          :src="formData.promotionalVideoUrl"
          width="750"
          height="450"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        >
        </iframe>
      </el-col>
    </el-row>
  </el-dialog>
</template>
