import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ListComponent from '../../../../core/infraestructure/ListComponent';
import SubscriptionPlanViewModel from '../../../../core/features/subscriptionplan/viewmodels/SubscriptionPlanViewModel';
import subscriptionPlanConfigs from "../../../../core/features/subscriptionplan/config";

export default {
  extends: ListComponent,
  data() {
    return {
      modulePermissions: 'SubscriptionPlansContent-SubscriptionPlans',
      item: null,
      searchName: null,
      searchCode: null,
      viewModel: null,
      typeFilter: null,
      types: subscriptionPlanConfigs.TYPES,
      modalImport: {
        isVisible: false,
        isLoading: false,
        title: 'IMPORTAR PLANES DE INCRIPCIÓN',
        onUploadImport: 'onUploadImportSubscriptionPlan',
        onCancelImport: 'onCancelImportSubscriptionPlan',
      },
    };
  },
  mounted() {
    EventBus.$on('onSubmitedSubscriptionPlan', this.onSearch);
    EventBus.$on('onUploadImportSubscriptionPlan', this.onUploadImport);
    EventBus.$on('onCancelImportSubscriptionPlan', this.onCancelImport);
  },
  beforeDestroy() {
    EventBus.$off('onSubmitedSubscriptionPlan', this.onSearch);
    EventBus.$off('onUploadImportSubscriptionPlan', this.onUploadImport);
    EventBus.$off('onCancelImportSubscriptionPlan', this.onCancelImport);
  },
  created() {
    this.viewModel = new SubscriptionPlanViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.onSearch();
    },
    onAllFilterChange(data, field) {
      this.onFilterChange(data, field);
    },
    onFocusType() {
      this.$forceUpdate()
    },
    onMapSubscriptionTypesLabel(type) {
      return this.viewModel.mapSubscriptionTypesLabel(type, subscriptionPlanConfigs)
    },
    onShowImport() {
      this.viewModel.showModal(this.modalImport, true);
    },
    onUploadImport(file){
      this.viewModel.showModalLoading(this.modalImport, true);
      this.viewModel.import(file);
    },
    onCancelImport(){
      this.viewModel.showModal(this.modalImport, false);
    },
    onExportToExcel() {
      this.viewModel.exportToExcel();
    },
    onCanCreate() {
      return this.canCreate(this.modulePermissions)
    },
    onCanRead() {
      return this.canRead(this.modulePermissions)
    },
    onCanUpdate() {
      return this.canUpdate(this.modulePermissions)
    },
    onCanDelete() {
      return this.canDelete(this.modulePermissions)
    },
  },
  computed: {}
}