<script>
import PasswordResetComponent from '../features/PasswordResetComponent';

export default {
  name: 'PasswordResetView',
  mixins: [PasswordResetComponent],
  components: { },
};
</script>
<template>
<!-- Signup Start -->
	<div class="container">
		<div class="row justify-content-lg-center justify-content-md-center">
			<div class="col-lg-12">
				<div class="main_logo25" id="logo">
					<a href="index.html"><img class="logo-login" src="images/logo.png" alt=""></a>
					<a href="index.html"><img class="logo-inverse" src="images/ct_logo.svg" alt=""></a>
				</div>
			</div>
		
			<div class="col-lg-6 col-md-8">
				<div class="sign_form">
					<h2>Cambiar Contraseña</h2>
					<el-form :model="formData" :rules="rules" ref="formData" status-icon :label-position="'left'" v-loading="isFormProcessing" :element-loading-text="formProcessingMessage">
						<div class="ui search focus mt-50">
							<div class="ui left icon input swdh95">
								<input class="prompt srch_explore" type="password" v-model="formData.password" id="id_password" required="" maxlength="64" placeholder="Ingresar contraseña...">															
								 <i class="uil uil-key-skeleton-alt icon icon2"></i>
							</div>
						</div>
                        <div class="ui search focus mt-50">
							<div class="ui left icon input swdh95">
								<input class="prompt srch_explore" type="password" v-model="formData.checkPass" id="id_password_confirmation" required="" maxlength="64" placeholder="Confirmar contraseña...">															
								 <i class="uil uil-key-skeleton-alt icon icon2"></i>
							</div>
						</div>
						<button class="login-btn" type="button" @click="onSend('formData')">Reiniciar Contraseña</button>
					</el-form>
					<p class="mb-0 mt-30">Regresar para <router-link :to="'/'" class="">Ingresar</router-link></p>
				</div>
				<div class="sign_footer"><img src="/images/logo_no_text.png" alt="" class="logo_footer">© 2020 <strong>AppMisionate</strong>. Todos los Derechos Reservados.</div>
			</div>				
		</div>				
	</div>				
	<!-- Signup End -->
</template>