<script>
import ModalSubscriptionComponent from '../../features/modals/ModalSubscriptionsComponent';
export default {
  extends: ModalSubscriptionComponent,
  name: 'ModalSubscriptionView',
};
</script>

<template>
  <el-dialog
    title="Inscripción"
    :visible.sync="conf.isVisible"
    width="30%"
    custom-class="subscription-modal"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      status-icon
      :label-position="'top'"
      v-loading="conf.isLoading"
    >
      <fieldset>
        <legend>Información de Inscripción</legend>
        <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item label="Usuario" prop="userProfile">
              <el-select v-model="formData.userProfile" clearable filterable remote :remote-method="onSearchUsers" placeholder="Seleccionar" value-key="id" size="small">
                <el-option v-for="item in users" :key="item.id" :label="item.firstName +' '+ item.lastName" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14">
            <el-form-item label="Plan de Inscripción" prop="subscriptionPlan">
              <el-select v-model="formData.subscriptionPlan" clearable filterable remote :remote-method="onSearchSubscriptionPlans" placeholder="Seleccionar" value-key="id" size="small">
                <el-option v-for="item in subscriptionPlans" :key="item.id" :label="item.name" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10" text-align="center">
            <el-form-item label="Precio" prop="amount">
              <el-input-number v-model="formData.amount" :precision="2" :step="0.1" size="small"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" text-align="center">
            <el-form-item label="F. Inscripción" prop="reportDate">
              <el-date-picker
                v-model="formData.reportDate"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="-"
                start-placeholder="F. Inicio"
                end-placeholder="F. Fin"
                value-format="yyyy-MM-dd"
                format="dd MMM yyyy"
                @blur="onFocusDate"
                size="small">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="Descuento">
              <el-select v-model="formData.discount" clearable filterable remote :remote-method="onSearchDiscounts" placeholder="Seleccionar" value-key="id" size="small">
                <el-option v-for="item in discounts" :key="item.id" :label="item.name" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </fieldset>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel" size="small" class="btn-cancel"
        >Cancelar</el-button
      >
      <el-button
        type="primary"
        @click="onSubmitForm('formData')"
        :disabled="!onCanSaveOrUpdate()"
        class="btn-primary"
        size="small"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>