import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ListComponent from '../../../../core/infraestructure/ListComponent';
import EvaluationViewModel from '../../../../core/features/evaluations/viewmodels/EvaluationViewModel';

export default {
  extends: ListComponent,
  data() {
    return {
      modulePermissions: 'ReviewsContent-Reviews',
      item: null,
      viewModel: null,
      courses: [],
      students: [],
      filterOptions: {
        qualificationFilter: 0,
        courseFilter: '',
        studentFilter: '',
      },
      modalImport: {
        isVisible: false,
        isLoading: false,
        title: 'IMPORTAR EVALUACIONES',
        onUploadImport: 'onUploadImportEvaluation',
        onCancelImport: 'onCancelImportEvaluation'
      }
    }
  },
  mounted() {
    EventBus.$on('onSubmitedEvaluation', this.onSearch);
    EventBus.$on('onUploadImportEvaluation', this.onUploadImport);
    EventBus.$on('onCancelImportEvaluation', this.onCancelImport);
  },
  beforeDestroy() {
    EventBus.$off('onSubmitedEvaluation', this.onSearch);
    EventBus.$off('onUploadImportEvaluation', this.onUploadImport);
    EventBus.$off('onCancelImportEvaluation', this.onCancelImport);
  },
  created() {
    this.viewModel = new EvaluationViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.onSearch();
    },
    onAllFilterChange(data, field) {
      this.onFilterChange(data, field);
    },
    onFilterDate(data, field) {
      this.viewModel.updateFilters(data, field, '=');
    },
    onRangeDate(data, startDate, endDate) {
      if(data) {
        this.onFilterDate(data[0], startDate);
        this.onFilterDate(data[1], endDate);
        this.onSearch();
      } else {
        this.viewModel.removeFilter(startDate);
        this.viewModel.removeFilter(endDate);
        this.onSearch();
      }
    },
    onSearchCourse(data) {
      if (data) {
        this.viewModel.findCourses(`Title=${data}`)
    } else {
        this.courses = []
    }
    },
    onSearchCoursesResponse(response) {      
      this.courses = response;
    },
    onSearchStudent(data) {
      if (data) {
        this.viewModel.findStudents(`FullName=${data}`)
    } else {
        this.students = []
    }
    },
    onSearchStudentsResponse(response) {
      this.students = response;
    },
    onFocus() { this.$forceUpdate() },
    onClearQualification() {
      _.remove(this.filters, { field: "Qualification" });
      this.filterOptions.qualificationFilter = 0;
      this.onActive();
    },
    onShowImport() {
      this.viewModel.showModal(this.modalImport, true);
    },
    onUploadImport(file){
      this.viewModel.showModalLoading(this.modalImport, true);
      this.viewModel.import(file);
    },
    onCancelImport(){
      this.viewModel.showModal(this.modalImport, false);
    },
    onExportToExcel() {
      this.viewModel.exportToExcel();
    },
    onCanCreate() {
      return this.canCreate(this.modulePermissions)
    },
    onCanRead() {
      return this.canRead(this.modulePermissions)
    },
    onCanUpdate() {
      return this.canUpdate(this.modulePermissions)
    },
    onCanDelete() {
      return this.canDelete(this.modulePermissions)
    },
    mapProfileImage(userProfile) {
      return (userProfile && userProfile.profileImageUrl) ? userProfile.profileImageUrl : '/images/left-imgs/img-1.jpg'
    }
  },
  computed: {}
}