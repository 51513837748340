import BaseComponent from "./BaseComponent";

export default {
    extends: BaseComponent,
    data() {
        return {
            filters: [],
            isItemLoading: false,
            itemLoadingMessage: "",
            fullLoader: true,
        };
    },
    methods: {
        onCancel() {
            this.conf.isVisible = false;
        },
        onSave(data) {
            var message = (data.state === 'create') ? "Creando registro..." : 'Actualizando registro...';
            this.showLoading(true, message);
            this.viewModel.onSave(data)
                .then((response) => this.onSaveResponse(response.data))
                .catch((error) => this.onSaveError(error.response))
        },
        onSaveError(error){
            this.showLoading(false, '');
            this.onError(error);
        },
        showLoading(isLoading, message){
            this.isItemLoading = isLoading;
            this.itemLoadingMessage = message
        },
        showComponent(show) {
            this.conf.isVisible = show;
        }
    }
};