import BaseApi from '../../../infraestructure/BaseApi';

export default class ApiHighSchools extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    createInStudent(data) {
        return this.client.post(`${this.endPoint}/in-student`, data)
    }
}