export default {
    sessionKey: 'session',
    isLogged () {
      if (!window.localStorage) return null
      const session = window.localStorage.getItem(this.sessionKey)
      return (session)
    },
    setSession (session) {
      if (window.localStorage) {
        window.localStorage.setItem(this.sessionKey, session)
      } else {
        // eslint-disable-next-line no-undef
        throw new Exception('Su navegador no soporta este tipo de autenticación, favor de actualizarlo.')
      }
    },
    getSession () {
      if (!window.localStorage) return null
      var parsedSession = this.parseJWT(window.localStorage.getItem(this.sessionKey))
      return this.mapSession(parsedSession)
    },
    removeSession () {
      if (!window.localStorage) return null
      window.localStorage.removeItem(this.sessionKey)
    },
    getAccessToken () {
      if (window.localStorage) {
        return window.localStorage.getItem(this.sessionKey)
      } else {
        // eslint-disable-next-line no-undef
        throw new Exception('Su navegador no soporta este tipo de autenticación, favor de actualizarlo.')
      }
    },
    parseJWT (token) {
      if (!token) return null
      var base64Url = token.split('.')[1]
      var base64 = base64Url.replace('-', '+').replace('_', '/')
      return JSON.parse(window.atob(base64))
    },
    mapSession (session) {
      if (!session) return null
      return {
        aud: session.aud,
        email: session.email,
        employee: session.employee,
        exp: session.exp,
        iss: session.iss,
        permissions: session.permissions.split(','),
        roles: session.roles.split(','),
        username: session.username,
        modules: session.modules.split(',')
      }
    }
  }
  