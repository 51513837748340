<script>
import HeaderView from '../../../shared/views/HeaderView';
import MainMenuInstructorView from '../../../shared/views/MainMenuInstructorView';
import MainFooterView from '../../../shared/views/MainFooterView';
import ListInstructorsNotificationsView from '../../views/lists/ListInstructorsNotificationsView';

export default {
  components: {
    'header-view': HeaderView,
    'main-menu-instructor-view': MainMenuInstructorView,
    'footer-view': MainFooterView,
    'list-instructors-notifications-view': ListInstructorsNotificationsView,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>
<template>
  <div>
    <header-view></header-view>
    <main-menu-instructor-view></main-menu-instructor-view>
    <!-- Body Start -->
    <list-instructors-notifications-view></list-instructors-notifications-view>
    <!-- Body End -->
  </div>
</template>