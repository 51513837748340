import _ from 'lodash'
import BaseViewModel from '../../../infraestructure/BaseViewModel'
import Student from '../../students/models/Student'
import Simulator from '../../simulator/models/SimulatorOfficial'

export default class DashboardStudentViewModel extends BaseViewModel {
  constructor(view) {
    super(view)
  }

  bindStats() {
    this.api.dashboardStudents.findStats()
      .then((response) => {
        this.view.onFindStatsResponse(response.data)
        return this.api.dashboardStudents.findCurrentCourses()
      })
      .then((response) => {
        this.view.onFindCurrentCoursesResponse(response.data)
        return this.api.dashboardStudents.findFinishedCourses()
      })
      .then((response) =>{
        this.view.onFindFinishedCoursesResponse(response.data)
      })
      .catch(this.view.onError);
  }

  findCurrentCourses() {
    this.api.dashboardStudents.findCurrentCourses()
      .then((response) => this.view.onFindCurrentCoursesResponse(response.data))
      .catch(this.view.onError)
  }

  findFinishedCourses() {
    this.api.dashboardStudents.findFinishedCourses()
      .then((response) => this.view.onFindFinishedCoursesResponse(response.data))
      .catch(this.view.onError)
  }


  bindCarousel(selector) {
    setTimeout(() => {
      $(selector).owlCarousel({
        items: 10,
        loop: false,
        margin: 20,
        nav: true,
        dots: false,
        navText: ["<i class='uil uil-angle-left'></i>", "<i class='uil uil-angle-right'></i>"],
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 2
          },
          1000: {
            items: 1
          },
          1200: {
            items: 2
          },
          1400: {
            items: 3
          }
        }
      })
    }, 300);
  }

  onCreateStudentInfo(data) {
    var item = new Student(data);
    item.state = 'create';
    return item
  }

  createSimulator(data) {
    var item = new Simulator(data);
    item.simulatorSections = [],
    item.state = 'create';
    return item
  }
  

}
