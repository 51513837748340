export default class Role {
  
  constructor(options) {
    Object.assign(this, options)
  }

  listItemName() {
    if(_.isNil(this.description) || _.isEmpty(this.description)) return this.name.toUpperCase();
    return `${this.name.toUpperCase()} - ${this.description}`
  }
}
