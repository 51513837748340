import { EventBus } from '../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../core/infraestructure/ItemComponent'
import AccountSubscriptionViewModel from '../../../core/features/authentication/viewmodels/AccountSubscriptionViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'SubscriptionsContent-Subscriptions',
            viewModel: null,
            subscriptionPlans: [],
            discountCode: null,
            planSelected: null,
            paymentMethodType: 'subscriptionCode',
            wizard: null,
            isLoading: false,
            subscriptionDetails: null,
            subscriptionSuccess: null,
            account: null,
            rules: {
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        setTimeout(() => {
            this.wizard = $('#add-course-tab').steps({
                onChange: this.onNextSubscription,
                onFinish: this.onPaySubscription
            });
            this.viewModel = new AccountSubscriptionViewModel(this);
            this.viewModel.onBindSubscriptions()
        }, 300);

    },
    mounted() { },
    methods: {
        onSubmitForm(form) {
            this.viewModel.doSubscription({
                planId: this.planSelected,
                discountCode: this.discountCode,
                paymentMethod: this.viewModel.getPaymentMethod(this.paymentMethodType)
            })
        },
        onSearchSubscriptionPlansResponse(response) {
            this.subscriptionPlans = response;
        },
        formatMoney(amount) {
            return this.viewModel.formatMoney(amount)
        },
        onNextSubscription(currentIndex, newIndex, stepDirection) {
            return this.subscriptionValidation(currentIndex, newIndex, stepDirection)
        },
        subscriptionValidation(currentIndex, newIndex, stepDirection) {
            if (currentIndex === 0 && !_.isEmpty(this.planSelected)) return true;
            if (currentIndex === 1) {
                if (this.paymentMethodType === 'subscriptionCode' && !_.isNil(this.discountCode)) {
                    return true;
                }
            }
            return false;
        },
        onPaySubscription() {
            this.isLoading = true;
            this.onSubmitForm();
        },
        onSubscriptionFinish() {
            this.discountCode = null;
            this.planSelected = null;
            this.conf.isVisible = false;
            EventBus.$emit('onFinishSubscriptionPayment', this.subscriptionDetails);
            this.subscriptionDetails = null;
        },
        onDoSubscriptionResponse(response) {
            setTimeout(() => {
                this.subscriptionDetails = response.data;
            }, 400);
            setTimeout(() => {
                this.isLoading = false;
            }, 600);
        },
        onDoSubscriptionError(error) {
            setTimeout(() => {
                this.isLoading = false;
            }, 400);

            var that = this;

            setTimeout(() => {
                var messages = _.flattenDeep(
                    _.toArray(_.toArray(error.response.data)[0])
                );
    
                messages.forEach(function (message) {
                    setTimeout(() => {
                        // that.viewModel.showNotification("warning", "Advertencia", message);
                        that.$confirm(message, 'Error', {
                            cancelButtonText: 'Aceptar',
                            type: 'error',
                            showConfirmButton: false,
                            cancelButtonClass:'btn-cancel'
                        })
                        .then(() => {
    
                        })
                        .catch(() => {
    
                        });
    
                    }, 150);
                });
            }, 600);
        },
        getPaymentMethodName() {
            if(this.paymentMethodType === 'subscriptionCode') return 'Efectivo'
        },
        getSubscriptionPlanMethodName() {
            var plan = _.find(this.subscriptionPlans, {id: this.planSelected });
            return (plan) ? plan.name : 'Sin Plan'
        },
        onHideBackButton() {
            return !this.subscriptionDetails;
        },
        onShowFinishButton() {
            return this.subscriptionDetails;
        }
    }
}