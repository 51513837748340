import BaseApi from '../../../infraestructure/BaseApi';

export default class ApiDashboardInstructor extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    findStats() {
        return this.client.get(`${this.endPoint}/stats`)
    }
}