import BaseApi from '../../../infraestructure/BaseApi';

export default class ApiLiveChat extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    doPublish(data) {
        return this.client.post(`${this.endPoint}`, data)
    }

    findByLiveClass(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}${parameters}`)
    }
}