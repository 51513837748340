<script>
import HeaderView from '../../../shared/views/HeaderView';
import MainMenuStudentView from '../../../shared/views/MainMenuStudentView';
import MainFooterView from '../../../shared/views/MainFooterView';
import ListExploreLiveClass from '../lists/ListExploreLiveClassView';
import ListExploreLiveClassFinished from '../lists/ListExploreLiveClassFinished';
import PermissionHelper from '../../../../core/shared/helpers/PermissionHelper';

export default {
  components: {
    'header-view': HeaderView,
    'main-menu-student-view': MainMenuStudentView,
    'footer-view': MainFooterView,
    'list-explore-live-class-view': ListExploreLiveClass,
    'list-explore-live-class-finished-view': ListExploreLiveClassFinished,
  },
  data() {
    return {
      activeName: 'active',
    };
  },
  methods: {
    onCanShow(moduleName) {
      return PermissionHelper.canShow(moduleName);
    },
  },
};
</script>

<template>
  <div>
    <header-view></header-view>
    <main-menu-student-view></main-menu-student-view>
    <div class="wrapper">
      <div class="sa4d25">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <div class="section3125">
                <h4 class="item_title"><i class="uil uil-play"></i> Todas las Clases en Vivo</h4>
                <div class="la5lo1">
                  <el-tabs v-model="activeName">
                    <el-tab-pane label="Activos" name="active">
                      <list-explore-live-class-view></list-explore-live-class-view>
                    </el-tab-pane>
                    <el-tab-pane label="Finalizados" name="finished">
                      <list-explore-live-class-finished-view></list-explore-live-class-finished-view>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer-view></footer-view>
    </div>
  </div>
</template>
