<script>
import MainMenuComponent from '../features/MainMenuComponent';

export default {
  name: 'MainMenuView',
  mixins: [MainMenuComponent],
};
</script>
<template>
  <!-- Left Sidebar Start -->
  <nav class="vertical_nav">
    <div class="left_section menu_left" id="js-menu">
      <div class="left_section">
        <ul>
          <li class="menu--item" v-if="onCanShowModule('Dashboard-Module')">
            <router-link :to="'/estudiante/resumen'" class="menu--link" title="Resumen">
              <i class="uil uil-apps menu--icon"></i>
              <span class="menu--label">Resumen</span>
            </router-link>
          </li>
          <li class="menu--item" v-if="onCanShowModule('LiveClassContent-Module')">
            <router-link
              :to="'/estudiante/en-vivo'"
              class="menu--link"
              title="Clases en vivo"
            >
              <i class="uil uil-play menu--icon"></i>
              <span class="menu--label">En Vivo</span>
            </router-link>
          </li>
          <li class="menu--item" v-if="onCanShowModule('CourseContent-Module')">
            <router-link :to="'/estudiante/cursos'" class="menu--link" title="Cursos">
              <i class="uil uil-book menu--icon"></i>
              <span class="menu--label">Cursos</span>
            </router-link>
          </li>
          <li class="menu--item" v-if="onCanShowModule('SimulatorsContent-Module')">
            <router-link
              :to="'/estudiante/simuladores'"
              class="menu--link"
              title="Simuladores"
            >
              <i class="fas fa-building menu--icon"></i>
              <span class="menu--label">Simuladores</span>
            </router-link>
          </li>
          <!-- <li class="menu--item" v-if="onCanShowModule('SubscriptionsContent-Statements')">
            <router-link
              :to="'/estudiante/suscripcion'"
              class="menu--link"
              title="Estado de suscripción"
            >
              <i class="uil uil-bill menu--icon"></i>
              <span class="menu--label">Suscripción</span>
            </router-link>
          </li> -->
          <li class="menu--item" v-if="onCanShowModule('NotificationsContent-Notifications')">
            <router-link
              :to="'/estudiante/notificaciones'"
              class="menu--link"
              title="Notificaciones"
            >
              <i class="uil uil-bell menu--icon"></i>
              <span class="menu--label" >Notificaciones</span>
            </router-link>
          </li>
          <li class="menu--item">
            <router-link
              :to="'/estudiante/mi-cuenta'"
              class="menu--link"
              title="Notificaciones"
            >
              <i class="uil uil-user-check menu--icon"></i>
              <span class="menu--label" >Mi Cuenta</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="left_footer">
        <ul>
          <li><a href="/">Inicio</a></li>
          <li><a href="/acerca">Acerca</a></li>
          <li><a href="/noticias">Noticias</a></li>
          <li><a href="/contacto">Contacto</a></li>
        </ul>
        <div class="left_footer_content">
          <p>
            © 2020 <strong>AppMisionate</strong>. Todos los Derechos Reservados.
          </p>
        </div>
      </div>
    </div>
  </nav>
  <!-- Left Sidebar End -->
</template>
