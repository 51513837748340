import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ListComponent from '../../../../core/infraestructure/ListComponent';
import RoleViewModel from '../../../../core/features/authorization/viewmodels/RoleViewModel';

export default {
  extends: ListComponent,
  data() {
    return {
      modulePermissions: 'Authorization-Roles',
      item: null,
      searchFullName: null,
      searchRole: null,
      viewModel: null,
      statuFilter: [],
      roleFilter: [],
      roles: [],
      loading: false,
      permissions: null,
    }
  },
  mounted() { 
    EventBus.$on('onSubmitedRole', this.onSearch);
  },
  beforeDestroy() {
    EventBus.$off('onSubmitedRole', this.onSearch);
  },
  created() {
    this.viewModel = new RoleViewModel(this, 'Roles');
    this.onActive();
  },
  methods: {
    onActive() {
      this.onSearch();
    },
    onAllFilterChange(data, field){
      this.onFilterChange(data, field);
    },
    onCanCreate() {
      return this.canCreate(this.modulePermissions)
    },
    onCanRead() {
      return this.canRead(this.modulePermissions)
    },
    onCanUpdate() {
      return this.canUpdate(this.modulePermissions)
    },
    onCanDelete() {
      return this.canDelete(this.modulePermissions)
    },
  },
  computed: {}
}