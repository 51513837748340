import './sass/site.scss';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import moment from 'moment';
import './plugins/axios';
// import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from '@/store/index';
import './registerServiceWorker';
import dateFilter from '@/filters/date.filter';
import ElementUI from 'element-ui';
import 'font-awesome/css/font-awesome.css';
import locale from 'element-ui/lib/locale/lang/es';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
// import './css/unicons-2.0.1/css/unicons.css';

Vue.use(ElementUI, {locale});

Vue.config.productionTip = false;

Vue.filter('date', dateFilter);

Vue.filter('formatDate', (value: any) => moment(String(value), moment.ISO_8601).format('DD MMM YYYY'));

Vue.filter('formatDWithTime', (value: any) => moment(String(value), moment.ISO_8601).format('DD MMM YYYY HH:mm:ss'));

new Vue({
  // vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
