<script>
import PasswordRecoveryComponent from '../features/PasswordRecoveryComponent';

export default {
  name: 'PasswordRecoveryView',
  mixins: [PasswordRecoveryComponent],
  components: { },
};
</script>
<template>
<!-- Signup Start -->
	<div class="container">
		<div class="row justify-content-lg-center justify-content-md-center">
			<div class="col-lg-12">
				<div class="main_logo25" id="logo">
					<a href="/"><img class="logo-login" src="images/logo.png" alt=""></a>
					<a href="/"><img class="logo-inverse" src="images/ct_logo.svg" alt=""></a>
				</div>
			</div>
		
			<div class="col-lg-6 col-md-8">
				<div class="sign_form">
					<h2>Solicitar Reinicio de Contrase&ntilde;a</h2>
					<el-form :model="formData" :rules="rules" ref="formData" status-icon :label-position="'left'" v-loading="isFormProcessing" :element-loading-text="formProcessingMessage">
						<div class="ui search focus mt-50">
							<div class="ui left icon input swdh95">
								<input class="prompt srch_explore" type="email" v-model="formData.email" id="id_email" required="" maxlength="64">
								<i class="uil uil-envelope icon icon2"></i>
							</div>
						</div>
						<button class="login-btn btn-primary" type="button" @click="onSend('formData')">Reiniciar Contraseña</button>
					</el-form>
					<p class="mb-0 mt-30">Regresar para <router-link class="link-primary" to="/login" >Ingresar</router-link></p>
				</div>
				<div class="sign_footer"><img src="/images/logo_no_text.png" alt="" class="logo_footer">© 2020 <strong>AppMisionate</strong>. Todos los Derechos Reservados.</div>
			</div>				
		</div>				
	</div>				
	<!-- Signup End -->
</template>