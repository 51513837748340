import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ListComponent from '../../../../core/infraestructure/ListComponent';
import ResponseActivityAnswerViewModel from '../../../../core/features/responseactivityanswer/viewmodels/ResponseActivityAnswerViewModel';

export default {
  extends: ListComponent,
  data() {
    return {
      modulePermissions: 'CourseContent-Courses',
      item: null,
      viewModel: null,
      courses: [],
      students: [],
      filterOptions: {
        courseSubjectActivityFilter: '',
        courseSubjectFilter: '',
        courseFilter: '',
        studentFilter: '',
      },
      modalImport: {
        isVisible: false,
        isLoading: false,
        title: 'IMPORTAR REVISION DE ACTIVIDADES',
        onUploadImport: 'onUploadImportResponseActivityAnswer',
        onCancelImport: 'onCancelImportResponseActivityAnswer'
      }
    }
  },
  mounted() {
    EventBus.$on('onSubmitedResponseActivityAnswer', this.onSearch);
    EventBus.$on('onUploadImportResponseActivityAnswer', this.onUploadImport);
    EventBus.$on('onCancelImportResponseActivityAnswer', this.onCancelImport);
  },
  beforeDestroy() {
    EventBus.$off('onSubmitedResponseActivityAnswer', this.onSearch);
    EventBus.$off('onUploadImportResponseActivityAnswer', this.onUploadImport);
    EventBus.$off('onCancelImportResponseActivityAnswer', this.onCancelImport);
  },
  created() {
    this.viewModel = new ResponseActivityAnswerViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.onSearch();
      this.onShowDetailAnswerResponse(this.$route.params.id)
    },
    onShowDetailAnswerResponse(id) {
      if(id) {
        this.onChangeItemOptions({ command: 'edit', item: { id: id } })
      }
    },
    onAllFilterChange(data, field) {
      this.onFilterChange(data, field);
    },
    onSearchCourse(data) {
      if (data) {
        this.viewModel.findCourses(`title=${data}`)
    } else {
        this.courses = []
    }
    },
    onSearchCoursesResponse(response) {      
      this.courses = response;
    },
    onFocusCourse() {
      this.$forceUpdate()
    },
    onSearchStudent(data) {
      if (data) {
        this.viewModel.findStudents(`FullName=${data}&Role=estudiante`)
    } else {
        this.students = []
    }
    },
    onSearchStudentsResponse(response) {
      this.students = response;
    },
    onFocusStudent() {
      this.$forceUpdate()
    },
    onShowImport() {
      this.viewModel.showModal(this.modalImport, true);
    },
    onUploadImport(file){
      this.viewModel.showModalLoading(this.modalImport, true);
      this.viewModel.import(file);
    },
    onCancelImport(){
      this.viewModel.showModal(this.modalImport, false);
    },
    onExportToExcel() {
      this.viewModel.exportToExcel();
    },
    onCanCreate() {
      return this.canCreate(this.modulePermissions)
    },
    onCanRead() {
      return this.canRead(this.modulePermissions)
    },
    onCanUpdate() {
      return this.canUpdate(this.modulePermissions)
    },
    onCanDelete() {
      return this.canDelete(this.modulePermissions)
    },
    mapProfileImage(userProfile) {
      return (userProfile && userProfile.profileImageUrl) ? userProfile.profileImageUrl : '/images/left-imgs/img-1.jpg'
    }
  },
  computed: {}
}