<script>
import HomeAdminDashboardComponent from '../../features/dashboards/HomeAdminDashboardComponent';

export default {
  extends: HomeAdminDashboardComponent,
  name: 'HomeAdminDashboardView',
  components: {},
};
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <h2 class="st_title"> Estad&iacute;sticas de Usuarios</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-3 col-lg-3 col-md-3" v-if="currentUserStats">
        <div class="card_dash">
          <div class="card_dash_left">
            <h5>Usuarios Activos</h5>
            <h2>{{ currentUserStats.totalUsersActive }}</h2>
            <!-- <span class="crdbg_3">New 5</span> -->
          </div>
          <div class="card_dash_right">
            <img src="/images/dashboard/promotion.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3" v-if="currentUserStats">
        <div class="card_dash">
          <div class="card_dash_left">
            <h5>Usuarios Inactivos</h5>
            <h2>{{ currentUserStats.totalUsersInactive }}</h2>
            <!-- <span class="crdbg_3">New 5</span> -->
          </div>
          <div class="card_dash_right">
            <img src="/images/dashboard/promotion.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3" v-if="currentUserStats">
        <div class="card_dash">
          <div class="card_dash_left">
            <h5>Inscripciones</h5>
            <h2>{{ currentUserStats.totalSubscriptions }}</h2>
            <!-- <span class="crdbg_3">New 5</span> -->
          </div>
          <div class="card_dash_right">
            <img src="/images/dashboard/graduation-cap.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3" v-if="currentUserStats">
        <div class="card_dash">
          <div class="card_dash_left">
            <h5>Inscripciones Activas</h5>
            <h2>{{ currentUserStats.totalSubscriptionsActive }}</h2>
            <!-- <span class="crdbg_3">New 5</span> -->
          </div>
          <div class="card_dash_right">
            <img src="/images/dashboard/graduation-cap.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <br>
    <br>
    <div class="row">
      <div class="col-lg-12">
        <h2 class="st_title"> Estad&iacute;sticas de Estudiantes en Cursos</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-3 col-lg-3 col-md-3" v-if="currentCourseStats">
        <div class="card_dash">
          <div class="card_dash_left">
            <h5>Cursos Favoritos</h5>
            <h2>{{ currentCourseStats.totalFavorite }}</h2>
            <!-- <span class="crdbg_3">New 5</span> -->
          </div>
          <div class="card_dash_right">
            <img src="/images/dashboard/online-course.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3" v-if="currentCourseStats">
        <div class="card_dash">
          <div class="card_dash_left">
            <h5>Cursos Que Gustan</h5>
            <h2>{{ currentCourseStats.totalLiked }}</h2>
            <!-- <span class="crdbg_3">New 5</span> -->
          </div>
          <div class="card_dash_right">
            <img src="/images/dashboard/online-course.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3" v-if="currentCourseStats">
        <div class="card_dash">
          <div class="card_dash_left">
            <h5>Cursos Que Desagradan</h5>
            <h2>{{ currentCourseStats.totalDisliked }}</h2>
            <!-- <span class="crdbg_3">New 5</span> -->
          </div>
          <div class="card_dash_right">
            <img src="/images/dashboard/online-course.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3" v-if="currentCourseStats">
        <div class="card_dash">
          <div class="card_dash_left">
            <h5>Cursos Finalizados</h5>
            <h2>{{ currentCourseStats.totalFinished }}</h2>
            <!-- <span class="crdbg_3">New 5</span> -->
          </div>
          <div class="card_dash_right">
            <img src="/images/dashboard/online-course.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
