import BaseApi from '../../../infraestructure/BaseApi';

export default class ApiPosts extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    doPublish(data) {
        return this.client.put(`${this.endPoint}/do-publish`, data)
    }
}