import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';
import EvaluationViewModel from '../../../../core/features/evaluations/viewmodels/EvaluationViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'ReviewsContent-Reviews',
            courses: [],
            students: [],
            viewModel: null,
            permissions: null,
            modules: [],
            rules: {
                qualification: [
                    { required: true, message: 'Calificación requerida.', trigger: 'change' }
                ],
                course: [
                    { required: true, message: 'Curso requerido.', trigger: 'change' }
                ],
                student: [
                    { required: true, message: 'Estudiante requerido.', trigger: 'change' }
                ],
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new EvaluationViewModel(this)
        this.onActive()
    },
    mounted() { },
    methods: {
        onActive() {
            this.viewModel.bindFormView();
        },
        onSearchCourse(data) {
            if (data) {
                this.viewModel.findCourses(`Title=${data}`)
            } else {
                this.courses = []
            }
        },
        onSearchCoursesResponse(response) {
            this.courses = response;
        },
        onSearchStudent(data) {
            if (data) {
                this.viewModel.findStudents(`FullName=${data}`)
            } else {
                this.students = []
            }
        },
        onSearchStudentsResponse(response) {
            this.students = response;
        },
        onFocus() {
            this.$forceUpdate()
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.onSave(this.formData)
                }
            });
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onSaveResponse(response) {
            this.showLoading(false, '');
            this.onShowSuccess(response.message);
            this.showComponent(false);
            EventBus.$emit('onSubmitedEvaluation', null);
        }
    }
}