import { saveAs } from 'file-saver'

export default {
  generateExcelDownload (streamData, fileName) {
    // eslint-disable-next-line no-undef
    const blob = new Blob([this.convertBase64ToBlob(streamData)], { type: 'application/vnd.ms-excel;charset=charset=utf-8' })
    saveAs(blob, fileName)
  },
  generatePdfDownload (streamData, fileName) {
    // eslint-disable-next-line no-undef
    const blob = new Blob([this.convertBase64ToBlob(streamData)], { type: 'application/pdf;charset=charset=utf-8' })
    saveAs(blob, fileName)
  },
  convertBase64ToBlob (stream) {
    // eslint-disable-next-line no-undef
    const byteCharacters = atob(stream)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    return new Uint8Array(byteNumbers)
  },
  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    })
  },
  getFilenameExtension(filename) {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename) : undefined;
  }
}
