<script>
import MainFooterView from '../../../shared/views/MainFooterView';
import ListNotificationsByAccountComponent from '../../features/Lists/ListNotificationsByAccountComponent';

export default {
  extends: ListNotificationsByAccountComponent,
  components: {
    'footer-view': MainFooterView,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>
<template>
  <!-- Body Start -->
  <div class="wrapper">
    <div class="sa4d25">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12">
            <h2 class="st_title">
              <i class="uil uil-bell"></i> Notificaciones
            </h2>
          </div>
        </div>
        <div class="row" v-loading="isListLoading" element-loading-spinner="el-icon-loading" :element-loading-text="listLoadingMessage">
          <div class="col-12">
            <!-- <a href="#" class="setting_noti">Notification Setting</a> -->
            <div class="all_msg_bg">
              <div
                class="channel_my item all__noti5"
                v-for="notification in collection"
                :key="notification.id"
              >
                <div class="profile_link">
                  <img :src="mapImage(notification.sender)" alt="" />
                  <div class="pd_content">
                    <h6>{{ notification.header }}</h6>
                    <p class="noti__text5">
                      {{ notification.message }}.
                      <strong v-if="notification.url" >
                        <el-button type="text" @click="onSelectNotification(notification)"
                          class="notification-view-more">Mostrar...</el-button
                        ></strong
                      >
                    </p>
                    <el-tag size="small" type="default" effect="plain" v-if="!notification.isRead">Nuevo</el-tag>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-pagination
          small
          layout="prev, pager, next"
          :page-count="pagination.totalPages"
          :current-page.sync="currentPage"
          @current-change="onPageChange"
        ></el-pagination>
      </div>
    </div>
    <footer-view></footer-view>
  </div>
  <!-- Body End -->
</template>
