import BaseComponent from '../../../core/infraestructure/BaseComponent'
import AuthViewModel from '../../../core/features/authentication/viewmodels/AuthViewModel'

export default {
  extends: BaseComponent,
  data() {
    return {
      viewModel: null,
      apiId: '664675910873070',
      apiVersion: 'v9.0'
    };
  },
  created() {
    this.viewModel = new AuthViewModel(this);
    this.preloadFacebookScript();
  },
  mounted() { },
  methods: {
    async preloadFacebookScript() {
      await this.loadFacebookSDK(document, "script", "facebook-jssdk");
      await this.initFacebook();
    },
    async logInWithFacebook() {
      window.FB.login((response) => {
        if (response.authResponse) {
          this.viewModel.loginFacebook({accessToken: response.authResponse.accessToken})
        } else {
          alert("User cancelled login or did not fully authorize.");
        }
      }, { perms: 'email' });

      return false;
    },
    async initFacebook() {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: this.apiId, //You will need to change this
          cookie: true, // This is important, it's not enabled by default
          version: this.apiVersion
        });
      };
    },
    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },
    onLoginResponse(response) {
      this.viewModel.showFormLoading(false, '')
      if (response.isAuthenticated) {
        this.viewModel.showNotification('success', 'Autenticación', response.message)
        this.viewModel.profile();
      } else {
        this.viewModel.showNotification('warning', 'Advertencia', response.message)
      }
    },
    onFindProfileResponse(response) {
      this.viewModel.setProfilePermissions(response.profile)
      switch (response.profile.role.name.toLowerCase()) {
        case 'root':
        case 'administrador':
        case 'administrador auxiliar':
          this.$router.push('/admin/resumen');
          break;
        case 'profesor':
          this.$router.push('/profesor/resumen');
          break;
        case 'estudiante':
          this.$router.push('/estudiante/resumen');
          break;
      }
    },
    onLoginError(response) {
      this.viewModel.showFormLoading(false, '')
      this.onError(response);
    }
  }
};