<script>
import FooterView from '../../shared/views/MainFooterView';
export default {
  name: 'SiteMapView',
  components: {
    'footer-view': FooterView,
  },
};
</script>

<template>
  <div>
    <!-- Header Start -->
    <header class="header clearfix">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="ml_item">
              <div class="main_logo main_logo15" id="website-logo">
                <router-link to="/">
                  <img src="/images/website_logo.png" alt="" />
                </router-link>
              </div>
            </div>
            <div class="header_right pr-0">
              <div class="back_link">
                <a href="/login" class="hde151">Ingresar</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- Header End -->
    <!-- Body Start -->
    <div class="wrapper _bg4586 _new89">
      <div class="_215cd2">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="course_tabs">
                <nav>
                  <div class="nav nav-tabs tab_crse  justify-content-center">
                    <a class="nav-item nav-link" href="/">Inicio</a>
                    <a class="nav-item nav-link" href="/acerca">Acerca</a>
                    <a class="nav-item nav-link" href="/noticias">Noticias</a>
                    <a class="nav-item nav-link" href="/contacto"
                      >Contacto</a
                    >
                  </div>
                </nav>
              </div>
              <div class="title129 mt-35 mb-35">
                <h2>Nuestro Sitio</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sa4d25">
        <div class="container">
          <div class="row" style="min-height:480px; ">
            <!-- <div class="col-xl-12 col-lg-8">
						<div class="section3125">
							<div class="explore_search">
								<div class="ui search focus">
                                    <br>
									<div class="ui left icon input swdh11">
										<input class="prompt srch_explore" type="text" placeholder="Search categories..">
										<i class="uil uil-search-alt icon icon2"></i>
									</div>
								</div>
							</div>							
						</div>							
					</div> -->
            <div class="col-md-12">
              <div class="section3125 mt-50">
                <!-- <h4 class="item_title  mb-0">Paginas del Sitio</h4> -->
                <div class="la5lo1 cate_521">
                  
                  <div class="_1frstcate">
                    <div class="row">
                      <div class="col-lg-3 col-md-4">
                        <div class="_cate101">
                            <i class="uil uil-arrow cate_icon1"></i><a href="/">Inicio</a>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-4">
                        <div class="_cate101">
                            <i class="uil uil-arrow cate_icon1"></i><a href="/acerca">Acerca</a>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-4">
                        <div class="_cate101">
                            <i class="uil uil-arrow cate_icon1"></i><a href="/noticias">Noticias</a>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-4">
                        <div class="_cate101">
                            <i class="uil uil-arrow cate_icon1"></i><a href="/contacto">Contacto</a>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-4">
                        <div class="_cate101">
                            <i class="uil uil-arrow cate_icon1"></i><a href="/soporte">Soporte</a>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-4">
                        <div class="_cate101">
                            <i class="uil uil-arrow cate_icon1"></i><a href="/terminos-y-condiciones">T&eacute;rminos y Condiciones</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer-view></footer-view>
    </div>
    <!-- Body End -->
  </div>
</template>
