export default class User {
  constructor(options) {
    Object.assign(this, options)
  }

  getFullName(){
    return `${this.firstName} ${this.lastName}`
  }

  getPermissions(){

  }

  toSend() {
    return {
      userId: this.id,
      userName: this.userName,
      password: this.password,
      firstName: this.firstName,
      lastName: this.lastName,
      roleId: this.role.id,
      jobDescription: this.jobDescription,
      employeeCode: this.employeeCode,
      email: this.email,
      status: this.status
    }
  }
}
