import _ from 'lodash'

export default {
  getUrlParameter(name) {
    name = name.replace(/[\\[]/, '\\[').replace(/[\]]/, '\\]')
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
    var results = regex.exec(window.location.search)
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
  },
  getSearchQuery(skip, limit, filterBy, orderBy) {
    const query = []

    if (!_.isNil(skip)) {
      query.push(`skip=${skip * (limit || 20)}`)
    } else {
      query.push(`skip=${0}`)
    }

    if (!_.isNil(limit)) {
      query.push(`limit=${limit}`)
    } else {
      query.push(`limit=${20}`)
    }

    if (!_.isNil(orderBy) && _.size(orderBy) > 0) {
      query.push(`orderby=${this.generateOrderParameter(orderBy)}`)
    }

    if (!_.isNil(filterBy) && _.size(filterBy) > 0) {
      query.push(this.generateFilterParameter(filterBy))
    }

    return query.join('&')
  },
  generateFilterParameter(collection) {
    const result = []
    _.forEach(collection, (item) => {
      result.push(item.field + item.condition + item.value)
    })
    // result = result.join(" ");
    // result = result.substring(0, result.length-2);
    // result = result.replace(/&/g, "and");
    return result.join('&')
  },
  generateOrderParameter(collection) {
    const result = []

    _.forEach(collection, (item) => {
      result.push(`${item.field} ${item.order}`)
    })

    return result.join('/')
  }
}
