<script>
import ListNotificationsComponent from '../../features/Lists/ListNotificationsComponent';
import MainFooterView from '../../../shared/views/MainFooterView';

export default {
  extends: ListNotificationsComponent,
  components: {
    'footer-view': MainFooterView,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>
<template>
  <!-- Body Start -->
  <div class="wrapper">
    <div class="sa4d25">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12">
            <h2 class="st_title">
              <i class="uil uil-bell"></i> Notificaciones
            </h2>
          </div>
        </div>
        <el-table
      :data="collection"
      empty-text="No se encontraron resultados."
      v-loading="isListLoading"
      element-loading-spinner="el-icon-loading"
      :element-loading-text="listLoadingMessage"
    >
      <el-table-column label="" width="70">
        <template slot-scope="scope">
          <div>
            <el-avatar :src="mapImage(scope.row.sender)"></el-avatar>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="ENVIA" width="250">
        <template slot-scope="scope">
          <div v-if="scope.row.sender">
            {{ scope.row.sender.firstName }} {{ scope.row.sender.lastName }}
          </div>
          <div v-else>
            APPMISIONATE PLATAFORMA
          </div>
        </template>
      </el-table-column>
      <el-table-column label="" width="70">
        <template slot-scope="scope">
          <div>
            <el-avatar :src="mapImage(scope.row.receiver)"></el-avatar>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="RECIBE" width="250">
        <template slot-scope="scope">
          <div v-if="scope.row.receiver">
            {{ scope.row.receiver.firstName }} {{ scope.row.receiver.lastName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="MENSAJE" min-width="220">
        <template slot-scope="scope">
          <div>
            {{ scope.row.message }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="ESTADO" width="90" align="center">
        <template slot-scope="scope">
          <el-tag type="default" effect="plain" v-if="!scope.row.isRead">Nuevo</el-tag>
          <el-tag type="default" effect="plain" v-if="scope.row.isRead">Leido</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createdAt" label="CREACIÓN" :width="140" align="center">
        <template slot-scope="scope">
          <el-tag  type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.createdAt | formatDate}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="updatedAt" label="ACTUALIZACIÓN" :width="140" align="center">
        <template slot-scope="scope">
          <el-tag v-if="onCheckUpdatedAt(scope.row.updatedAt)" type="default" effect="plain"><i class="el-icon-date"></i> {{scope.row.updatedAt | formatDate}}</el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column
        label="OPCIONES"
        :width="140"
        align="center"
        text-align="center"
      >
        <template slot-scope="scope">
          <el-dropdown @command="onChangeItemOptions">
            <el-button size="small">
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="{ command: 'edit', item: scope.row }"
                :disabled="!onCanRead(modulePermissions)"
              >
                <i class="el-icon-edit" aria-hidden="true"></i> Mostrar
              </el-dropdown-item>
              <el-dropdown-item
                :command="{ command: 'delete', item: scope.row }"
                :disabled="!onCanDelete(modulePermissions)"
              >
                <i class="el-icon-delete" aria-hidden="true"></i> Eliminar
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column> -->
    </el-table>
        <el-pagination
          small
          layout="prev, pager, next"
          :page-count="pagination.totalPages"
          :current-page.sync="currentPage"
          @current-change="onPageChange"
        ></el-pagination>
      </div>
    </div>
    <footer-view></footer-view>
  </div>
  <!-- Body End -->
</template>
