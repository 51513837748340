import EndPoints from '../../../infraestructure/EndPoints'
const endPoint = `${EndPoints.API}${EndPoints.USERS}`

export default class ApiUsers {
    constructor(client) {
        this.client = client
    }

    create(data) {
        return this.client.post(`${endPoint}/register`, data)
    }

    update(id, data) {
        return this.client.put(`${endPoint}/${id}`, data)
    }

    delete(id) {
        return this.client.delete(`${endPoint}/${id}`)
    }

    findProfile() {
        return this.client.get(`${endPoint}/profile`);
    }

    find(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${endPoint}${parameters}`)
    }

    findOne(id) {
        return this.client.get(`${endPoint}/${id}`)
    }

    verifyUser(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${endPoint}/verify-user-name${parameters}`)
    }

    verifyEmail(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${endPoint}/verify-user-email${parameters}`)
    }

    import(file) {
        const fd = new FormData()
        fd.append('file', file)
        return this.client.post(`${endPoint}/import`, fd)
    }

    export() {
        return this.client.get(`${endPoint}/export-excel`)
    }
}