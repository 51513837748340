import BaseComponent from '../../../core/infraestructure/BaseComponent'

export default {
    extends: BaseComponent,
    data() {
        return {
            progressPercentage: 0,
            showProgress: false,
            courseProgressColors: [
                {color: '#5cb87a', percentage: 100}
              ]
        };
    },
    created() {
        this.$root.$on('update-progress', this.onUpdateProgress);
    },
    mounted() {},
    methods: {
        onUpdateProgress(response) {
            this.showProgress = response.show;
            this.progressPercentage = response.percentage;
        }
    }
};
