import BaseApi from '../../../infraestructure/BaseApi';

export default class ApiLiveClasses extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    doFinish(liveClassId, data) {
        return this.client.put(`${this.endPoint}/${liveClassId}/do-finish`, data)
    }

    findByPublisher(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/by-publisher${parameters}`)
    }

    findByActives(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/actives${parameters}`)
    }

    findByFinished(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/finished${parameters}`)
    }
}