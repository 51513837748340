<script>
import ListUsersComponent from '../../features/lists/ListUsersComponent';
import ModalUserView from '../modals/ModalUserView';
import ModalImportView from '../../../shared/views/ModalImportView';


export default {
  extends: ListUsersComponent,
  name: 'ListUserView',
  components: {
    'modal-user-view': ModalUserView,
    'modal-import': ModalImportView,
  },
};
</script>

<template>
  <div class="list-body">
    <el-row :gutter="20" class="section-ctrl users">
      <el-col :xs="24" :sm="13" :md="13" :lg="9" :xl="6" class="create-section">
        <el-button-group class="btn-grp-drp">
          <el-button
            type="primary"
            size="small"
            class="btn-primary"
            icon="el-icon-circle-plus"
            @click="onCreate"
            :disabled="!onCanCreate(modulePermissions)"
            >Crear Usuario</el-button
          >
          <el-dropdown>
            <el-button size="small" class="btn-primary-light">
              <i class="fa fa-ellipsis-v"></i> <span class="hidden-xs-only">M&aacute;s Opciones</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button
                  size="small"
                  class="btn-primary-light"
                  icon="el-icon-upload"
                  @click="onShowImport"
                  :disabled="!onCanCreate(modulePermissions)"
                  >Importar Usuarios</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  size="small"
                  class="btn-primary-light btn-primary-full"
                  @click="onExportToExcel()"
                  :disabled="!onCanRead(modulePermissions)"
                >
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i> Exportar
                  Excel
                </el-button>
              </el-dropdown-item>
              <!--<el-dropdown-item>
                <el-button size="small" class="btn-white">
                  <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Exportar PDF
                </el-button>
              </el-dropdown-item>-->
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown>
            <el-button type="primary" size="small" class="btn-secondary">
              <i class="fa fa-filter" aria-hidden="true"></i> <span class="hidden-xs-only">Filtrar Por</span> <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-select size="small" v-model="filterOptions.status" clearable placeholder="Estado..." 
                  @change="onAllFilterChange(filterOptions.status, 'Status')" @focus="onFocusUserStatus" value-key="id">
                  <el-option v-for="item in userStatuses" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-select size="small" v-model="filterOptions.roleFilter" filterable remote clearable placeholder="Role..."
                :remote-method="onSearchRole" @change="onAllFilterChange(filterOptions.roleFilter.name, 'Role')" @focus="onFocusRole"
                value-key="id">
                  <el-option v-for="item in roles" :key="item.id" :label="item.name" :value="item"></el-option>
                </el-select>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-button-group>
      </el-col>
      <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="4">
        <el-tooltip class="item" effect="dark" content="Buscar por Usuario." placement="top">
          <el-input size="small" placeholder="Buscar Usuario..." icon="search" v-model='searchFullName'
          @keyup.enter.native="onAllFilterChange(searchFullName, 'UserName')" suffix-icon="el-icon-search"></el-input>
        </el-tooltip>
      </el-col>
      <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="4">
        <el-tooltip class="item" effect="dark" content="Buscar por correo electronico." placement="top">
          <el-input size="small" placeholder="Buscar Correo..." icon="search" v-model='searchEmail'
          @keyup.enter.native="onAllFilterChange(searchEmail, 'Email')" suffix-icon="el-icon-search"></el-input>
        </el-tooltip>
      </el-col>
    </el-row>
    <el-table
      :data="collection"
      empty-text="No se encontraron resultados."
      v-loading="isListLoading"
      element-loading-spinner="el-icon-loading"
      :element-loading-text="listLoadingMessage"
    >
      <!-- <el-table-column label="NO. EMPLEADO" width="140" align="center">
        <template scope="scope">
          <div>
            <el-tag type="info" effect="plain">
              {{ scope.row.employeeCode }}</el-tag
            >
          </div>
        </template>
      </el-table-column> -->
      <el-table-column label="USUARIO" min-width="180">
        <template slot-scope="scope">
         <div>
            <el-tag type="default" effect="plain"
              ><i class="uil uil-user"></i>
              {{ scope.row.userName.toUpperCase() }}</el-tag
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column label="CORREO" min-width="350">
        <template slot-scope="scope">
          <div>
            <el-tag type="default" effect="plain"
              ><i class="uil uil-envelope-check"></i>
              {{ scope.row.email.toUpperCase() }}</el-tag
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column label="ROL" align="center" min-width="150">
        <template slot-scope="scope">
          <div>
            <el-tag type="default" effect="plain"
              ><i class="uil uil-users-alt"></i>
              {{ scope.row.role.toUpperCase() }}</el-tag
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="ESTADO"
        align="center"
        text-align="center"
        width="110"
      >
        <template slot-scope="scope">
          <div>
            <el-tag v-if="!scope.row.status || scope.row.status === 1" type="danger" effect="plain"
              ><i class="fa fa-times-circle"></i> INACTIVO</el-tag
            >
            <el-tag v-if="scope.row.status === 2" type="success" effect="plain"
              ><i class="fa fa-check-circle"></i> ACTIVO</el-tag
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="OPCIONES"
        :width="160"
        align="center"
        text-align="center"
      >
        <template slot-scope="scope">
          <el-dropdown @command="onChangeItemOptions">
            <el-button size="small">
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="{ command: 'edit', item: scope.row }"
                :disabled="!onCanRead(modulePermissions)"
              >
                <i class="el-icon-edit" aria-hidden="true"></i> Mostrar
              </el-dropdown-item>
              <el-dropdown-item
                :command="{ command: 'delete', item: scope.row }"
                :disabled="!onCanDelete(modulePermissions)"
              >
                <i class="el-icon-delete" aria-hidden="true"></i> Eliminar
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      small
      layout="prev, pager, next"
      :page-count="pagination.totalPages"
      :current-page.sync="currentPage"
      @current-change="onPageChange"
    ></el-pagination>
    <modal-user-view
      :formData="item"
      :conf="modalItem"
      v-if="modalItem.isVisible"
    ></modal-user-view>
    <modal-import :conf="modalImport"></modal-import>
  </div>
</template>
