export default class Evaluation {
    constructor(options) {
      Object.assign(this, options)
    }
    
    getPermissions(){
  
    }
  
    toSend() {
      return {
        id: this.id,
        studentId: this.student.id,
        courseId: this.course.id,
        qualification: this.qualification,
        observation: this.observation,
      }
    }
  }
  