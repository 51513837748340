import BaseApi from '../../../infraestructure/BaseApi';

export default class ApiEvaluations extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    doCourseEvaluation(data) {
        return this.client.post(`${this.endPoint}/do-course-evaluation`, data)
    }
}