export default class CourseCategory {
    constructor(options) {
      Object.assign(this, options)
    }
  
    getFullName(){
      return `${this.firstName} ${this.lastName}`
    }
  
    getPermissions(){
  
    }
  
    toSend() {
      return {
        id: this.id,
        code: this.code,
        name: this.name,
        description: this.description,
        parent: (this.parent) ? this.parent.id : null
      }
    }
  }
  