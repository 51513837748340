<script>
export default {
  name: 'MainMenuView',
};
</script>
<template>
  <footer class="footer mt-30">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="item_f1">
            <a href="/">Inicio</a>
            <a href="/acerca">Acerca</a>
            <a href="/noticias">Noticias</a>
            <a href="/contacto">Contacto</a>
            <a href="/soporte">Soporte</a>
            <a href="/terminos-y-condiciones">Términos y Condiciones</a>
            <a href="/mapa-del-sitio">Mapa del Sitio</a>
          </div>
        </div>
        <!-- <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="item_f1">
            <a href="/soporte">Soporte</a>
            <a href="/terms">Terminos y Políticas</a>
            <a href="/sitemap">Mapa del Sitio</a>
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-6">
          <div class="item_f1">
            <a href="terms_of_use.html">Políticas de Copyright</a>
            <a href="terms_of_use.html">Terminos de Uso</a>
            <a href="terms_of_use.html">Políticas de Privacidad</a>
          </div>
        </div> -->
        <!-- <div class="col-lg-3 col-md-3 col-sm-6">
          <div class="item_f3">
            <a href="#" class="btn1542">Enseñar en AppMisionate</a>
            
          </div>
        </div>-->
        <div class="col-lg-12">
          <div class="footer_bottm">
            <div class="row">
              <div class="col-md-6">
                <ul class="fotb_left">
                  <li>
                    <a href="/">
                      <div class="footer_logo">
                        <img src="images/website_logo_footer.png" alt="" />
                      </div>
                    </a>
                  </li>
                  <li>
                    <p>
                      © 2021 <strong>AppMisionate</strong>. Todos los Derechos
                      Reservados.
                    </p>
                  </li>
                </ul>
              </div>
              <div class="col-md-6">
                <div class="edu_social_links">
                  <a href="#"><i class="fab fa-facebook-f"></i></a>
                  <a href="#"><i class="fab fa-twitter"></i></a>
                  <a href="#"><i class="fab fa-google-plus-g"></i></a>
                  <a href="#"><i class="fab fa-linkedin-in"></i></a>
                  <a href="#"><i class="fab fa-instagram"></i></a>
                  <a href="#"><i class="fab fa-youtube"></i></a>
                  <a href="#"><i class="fab fa-pinterest-p"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
