<script>
import ModalImportComponent from '../features/ModalImportComponent';

export default {
    extends: ModalImportComponent,
    name: 'ModalImportView',
};
</script>
<template>
    <el-dialog :title="conf.title"
    custom-class="modal-import"
    :visible.sync="conf.isVisible"
    width="25%"
    :before-close="onCancel"
    :close-on-click-modal="false">
    <el-form :model="formData" :rules="rules" ref="formData" status-icon :label-position="'top'" v-loading="conf.isLoading">
      <fieldset>
        <legend>Seleccionar Archivo</legend>
        <el-row >
          <el-col :span="24">
              <el-upload class="upload-demo" action="" :before-upload="onUpload" :show-file-list="false">
                <el-button size="small" type="primary">Click para subir archivo</el-button>
                <div slot="tip" class="el-upload__tip">Solo archivos en formato Excel</div>
              </el-upload>
          </el-col>
        </el-row>
      </fieldset>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel" size="small">Cancelar</el-button>
    </span>
  </el-dialog>
</template>