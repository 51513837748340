import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent'

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'LiveClassContent-LiveClass',
            viewModel: null,
            permissions: null,
            modules: [],
            courseFilter: '',
            courses: [],
            rules: {
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {

    },
    mounted() { },
    methods: {

    }
}