<script>
import NotificationAlertsComponent from '../features/NotificationAlertsComponent';

export default {
  name: 'NotificationAlertsView',
  mixins: [NotificationAlertsComponent],
  components: {},
};
</script>

<template>
  <li class="ui dropdown">
    <span href="#" class="option_links" title="Notifications"
      ><i class="uil uil-bell"></i><span class="noti_count">{{totalAlertsNotifications}}</span></span
    >
    <div class="menu dropdown_mn">
      <span @click="onSelectNotification(notification)" class="channel_my item" v-for="notification in notificationAlerts" :key="notification.id">
        <div class="profile_link">
          <img :src="mapImage(notification.sender)" alt="" />
          <div class="pd_content">
            <h6 v-if="notification.header">{{notification.header}}</h6>
            <p v-if="notification.message" v-html="notification.message"></p>
            <!-- <span class="nm_time">Hace 2 minutos</span> -->
          </div>
        </div>
      </span>
      <router-link class="vbm_btn" to="notificaciones"
        >Ver Todos <i class="uil uil-arrow-right"></i
      ></router-link>
    </div>
  </li>
</template>
