import _ from 'lodash'
import BaseViewModel from '../../../infraestructure/BaseViewModel'
import Notification from '../models/Notification'
import Student from '../../students/models/Student'

export default class NotificationViewModel extends BaseViewModel {
  constructor(view) {
    super(view)
  }
  // INICIALIZADORES

  static AccountConfirmationEmail = 1;
  static AccountPasswordResetEmail = 2;
  static AccountInformationAlert = 3;
  static CourseActivityReview = 4;

  //#region CRUD

  onCreate() {
    let item = new Notification({
      state: "create",
      status: 1,
      courseSubjects: [],
    });
    return item;
  }

  onDelete(data) {
    let item = new Notification(data);
    item.state = "delete";
    return item;
  }

  onEdit(data) {
    let item = new Notification(data);
    item.state = "update";
    return item;
  }

  onSearchCollection() {
    return this.api.notifications.find(this.getSearchQuery())
  }

  mapCollection(collection) {
    return _.map(collection, (data) => new Notification(data));
  }

  onSearchItem(id) {
    return this.api.notifications.findOne(id)
  }

  onSave(data) {
    if (data.state === 'create')
      return this.api.notifications.create(data.toSend())
    else
      return this.api.notifications.update(data.id, data.toSend());
  }

  onDestroy(context, id) {
    context.api.notifications.delete(id)
      .then(response => context.view.onSuccess(response.data))
      .catch(context.view.onError)
  }

  onCancel(context) {
    context.view.item = null;
  }

  import(file) {
    this.api.notifications.import(file)
      .then(response => this.view.onImportResponse(response.data))
      .catch(this.view.onError)
  }

  exportToExcel() {
    this.api.notifications.exportToExcel()
      .then(response => this.view.onExportResponse(response.data))
      .catch(this.view.onError)
  }

  // #endregion

  onCreateStudentInfo(data) {
    var item = new Student(data);
    item.state = 'create';
    return item
  }

  // #region BINDING DATA

  bindFormView() {
    if (this.view.formData.state === 'update') {
      
    }
  }



  // #endregion

  // #region QUERIES

  /**
   * Metodo para buscar categorias de cursos
   * @param {String} data Query string para parametros de búsqueda
   */
  doMarkAsRead(data) {
    this.api.notifications.doMarkAsRead(data.id)
      .then((response) => this.view.onMarkAsReadResponse(response.data.data))
      .catch(this.view.onError)
  }

  findAlerts() {
    this.api.notifications.findAlerts()
    .then((response) => this.view.onFindAlertsResponse(response.data.data))
    .catch(this.view.onError)
  }

  findByAccount() {
    this.view.showLoading(true, 'Actualizando registros...');
    this.api.notifications.findByAccount(this.getSearchQuery())
    .then((response) => this.view.onSearchResponse(response.data, response.headers))
    .catch(this.view.onError)
  }

  findNotification(id, detailId) {
    this.api.notifications.findOne(id)
    .then((response) => this.view.onFindNotificationResponse(response.data.data, detailId))
    .catch(this.view.onError)
  }

  findResponseAnswerActivity(id) {
    this.api.responseActivityAnswers.findOne(id)
    .then((response) => this.view.onFindResponseAnswerActivityResponse(response.data.data))
    .catch(this.view.onError)
  }


  // #endregion

  //#region MAPPING DATA

  

  // #endregion

  // #region VALIDATIONS

  isStudentActivity(value) {
    return _.includes(value, '/estudiante/actividades/')
  }

  getStudentActivityId(value) {
    var collection = value.split('/')
    return collection[3]
  }
  // #endregion
}
