<script>
import HeaderView from '../../shared/views/HeaderView';
import MainMenuInstructorView from '../../shared/views/MainMenuInstructorView';
import MainFooterView from '../../shared/views/MainFooterView';
import ModuleInstructorAccountComponent from '../features/ModuleInstructorAccountComponent';
import ModalInstructorView from '../../../components/instructors/views/modals/ModalInstructorView';

export default {
  extends: ModuleInstructorAccountComponent,
  components: {
    'header-view': HeaderView,
    'main-menu-instructor-view': MainMenuInstructorView,
    'main-footer-view': MainFooterView,
    'modal-instructor-view': ModalInstructorView,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>
<template>
  <div>
    <header-view></header-view>
    <main-menu-instructor-view></main-menu-instructor-view>
    <!-- Body Start -->
    <div class="wrapper _bg4586">
      <div class="_216b01">
        <div class="container-fluid">
          <div class="row justify-content-md-center">
            <div class="col-md-10">
              <div class="section3125 rpt145">
                <div class="row">
                  <div class="col-lg-7">
                    <!-- <a href="#" class="_216b22">
                      <span><i class="uil uil-cog"></i></span>Setting
                    </a> -->
                    <div class="dp_dt150">
                      <div class="img148">
                        <img :src="profileImageUrl" alt="" />
                      </div>
                      <div class="prfledt1" v-if="session">
                        <h2>
                          {{ session.user.firstName }}
                          {{ session.user.lastName }}
                        </h2>
                        <span>{{ session.user.email }}</span>
                      </div>
                    </div>
                    <ul class="_ttl120">
                      <li>
                        <div class="_ttl121">
                          <div class="_ttl122">Estudiantes Activos</div>
                          <div class="_ttl123" v-if="session && session.instructor">{{session.instructor.totalStudents}}</div>
                        </div>
                      </li>
                      <li>
                        <div class="_ttl121">
                          <div class="_ttl122">Cursos</div>
                          <div class="_ttl123" v-if="session && session.instructor">{{session.instructor.totalCourses}}</div>
                        </div>
                      </li>
                      <li>
                        <div class="_ttl121">
                          <div class="_ttl122">Reseñas</div>
                          <div class="_ttl123" v-if="session && session.instructor">{{session.instructor.totalReviews}}</div>
                        </div>
                      </li>
                      <li>
                        <div class="_ttl121">
                          <div class="_ttl122">Notificaciones</div>
                          <div class="_ttl123" v-if="session && session.instructor">{{session.instructor.totalNotifications}}</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="col-lg-5">
                    <!-- <a href="setting.html" class="_216b12">
                      <span><i class="uil uil-cog"></i></span>Setting
                    </a> -->
                    <div class="rgt-145">
                      <ul
                        class="tutor_social_links"
                        v-if="session && session.instructor"
                      >
                        <li v-if="session.instructor.pageUrlFacebook">
                          <a
                            :href="session.instructor.pageUrlFacebook"
                            target="_blank"
                            class="fb"
                            ><i class="fab fa-facebook-f"></i
                          ></a>
                        </li>
                        <li v-if="session.instructor.pageUrlInstagram">
                          <a
                            :href="session.instructor.pageUrlInstagram"
                            target="_blank"
                            class="in"
                            ><i class="fab fa-instagram"></i
                          ></a>
                        </li>
                        <li v-if="session.instructor.pageUrlLinkedIn">
                          <a
                            :href="session.instructor.pageUrlLinkedIn"
                            target="_blank"
                            class="ln"
                            ><i class="fab fa-linkedin-in"></i
                          ></a>
                        </li>
                      </ul>
                    </div>
                    <ul class="_bty149">
                      <li>
                        <button
                          class="studio-link-btn btn500 btn-primary"
                          @click="onEditProfile"
                        >
                          Editar
                        </button>
                      </li>
                      <!-- <li>
                        <button
                          class="msg125 btn500"
                          onclick="window.location.href = 'setting.html';"
                        >
                          Edit
                        </button>
                      </li> -->
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="_215b15">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="course_tabs">
                <nav>
                  <div
                    class="nav nav-tabs tab_crse"
                    id="nav-tab"
                    role="tablist"
                  >
                    <a
                      class="nav-item nav-link active"
                      id="nav-about-tab"
                      data-toggle="tab"
                      href="#nav-about"
                      role="tab"
                      aria-selected="true"
                      >Acerca</a
                    >
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="_215b17">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="course_tab_content">
                <div class="tab-content" id="nav-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="nav-about"
                    role="tabpanel"
                  >
                    <div class="_htg451">
                      <div class="container" >
                        <h3>Acerca de mi</h3>
                        <p>
                          {{sessionAbout}}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <main-footer-view></main-footer-view>
    </div>
    <!-- Body End -->
    <modal-instructor-view
      :formData="item"
      :conf="modalItem"
      :showStatus="false"
      v-if="modalItem.isVisible"
    ></modal-instructor-view>
    <div v-loading.fullscreen.lock="fullLoader"></div>
  </div>
</template>
