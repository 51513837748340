import BaseViewModel from '../../../infraestructure/BaseViewModel'

export default class AccountRegisterViewModel extends BaseViewModel {
  constructor (view) {
    super(view)
  }
  
  signUp(data) {
    this.api.authentication.register(data)
      .then(response => this.view.onSignUpResponse(response.data))
      .catch(this.view.onHandleError)
  }
}