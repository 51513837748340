<script>
import ModalUserComponent from '../../features/modals/ModalUserComponent';
export default {
  extends: ModalUserComponent,
  name: 'ModalUserView',
};
</script>
<template>
  <el-dialog
    title="Usuario"
    :visible.sync="conf.isVisible"
    width="60%"
    custom-class="user-modal"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      status-icon
      :label-position="'top'"
      v-loading="conf.isLoading"
    >
      
        <fieldset>
            <legend>Información de Cuenta</legend>
            <el-row class="row-bg" :gutter="10">
              <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="4">
                <el-form-item label="Role" prop="role">
                  <el-select v-model="formData.role"
                    clearable
                    filterable
                    remote
                    :remote-method="onSearchRoles"
                    placeholder="Seleccionar"
                    value-key="id"
                    size="small"
                  >
                    <el-option
                      v-for="item in roles"
                      :key="item.id"
                      :label="item.name"
                      :value="item"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              
              <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="10">
                <el-form-item label="Nombre(s)" prop="firstName">
                  <el-input
                    v-model="formData.firstName"
                    maxlength="70"
                    size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="10">
                <el-form-item label="Apellido(s)" prop="lastName">
                  <el-input v-model="formData.lastName" maxlength="70" size="small"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="row-bg" :gutter="20">
              
              
              <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="18">
                <el-form-item label="Correo Electronico" prop="email">
                  <el-input v-model="formData.email" size="small"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="4">
                <el-form-item label="Estado" prop="status">
                  <el-switch
                    style="display: block"
                    v-model="formData.status"
                    active-color="#13ce66"
                    inactive-text="Inactivo"
                    active-text="Activo"
                    :inactive-value="1"
                    :active-value="2"
                  ></el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" :gutter="20" v-if="formData.id">
              <el-col :span="24">
                <el-checkbox v-model="updatePassword"
                  >Actualizar password</el-checkbox
                >
              </el-col>
            </el-row>
            <el-row class="row-bg" :gutter="20">
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                <el-form-item label="Usuario" prop="userName">
                  <el-input v-model="formData.userName" size="small"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                <el-form-item label="Contraseña:" prop="password">
                  <el-input
                    size="small"
                    type="password"
                    v-model="formData.password"
                    placeholder="Contraseña"
                    :disabled="!isPasswordRequired()"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                <el-form-item label="Verificar Contraseña:" prop="checkPass">
                  <el-input
                    size="small"
                    type="password"
                    v-model="formData.checkPass"
                    placeholder="Verificar Contraseña"
                    :disabled="!isPasswordRequired()"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            </fieldset>
      
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel" size="small" class="btn-cancel"
        >Cancelar</el-button
      >
      <el-button
        type="primary"
        @click="onSubmitForm('formData')"
        class="btn-primary"
        size="small"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>
