<script>
import FooterView from '../../shared/views/MainFooterView';
export default {
  name: 'TermsPoliticsView',
  components: {
    'footer-view': FooterView,
  },
};
</script>

<template>
  <div>
    <!-- Header Start -->
    <header class="header clearfix">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="ml_item">
              <div class="main_logo main_logo15" id="website-logo">
                <router-link to="/">
                  <img src="/images/website_logo.png" alt="" />
                </router-link>
              </div>
            </div>
            <div class="header_right pr-0">
              <div class="back_link">
                <a href="/login" class="hde151">Ingresar</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- Header End -->
    <!-- Body Start -->
    <div class="wrapper _bg4586 _new89">
      <div class="_215cd2">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="course_tabs">
                <nav>
                  <div class="nav nav-tabs tab_crse  justify-content-center">
                    <a class="nav-item nav-link" href="/">Inicio</a>
                    <a class="nav-item nav-link" href="/acerca">Acerca</a>
                    <a class="nav-item nav-link" href="/noticias">Noticias</a>
                    <a class="nav-item nav-link" href="/contacto">Contacto</a>
                  </div>
                </nav>
              </div>
              <div class="title129 mt-35 mb-35">
                <h2>Soporte</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="faq1256">
			<div class="container">
				<div class="row">
					<div class="col-lg-3 col-md-5">
						<div class="fcrse_3 frc123">
							<div class="cater_ttle">
								<h4>Temas de Ayuda</h4>
							</div>
							<ul class="allcate15">
								<li><a href="#" class="ct_item"><i class="uil uil-arrow"></i>Cuentas</a></li>
								<li><a href="#" class="ct_item active"><i class="uil uil-graph-bar"></i>Pagos</a></li>
								<li><a href="#" class="ct_item"><i class="uil uil-monitor"></i>Simuladores</a></li>
								<li><a href="#" class="ct_item"><i class="uil uil-ruler"></i>Creación de Cursos</a></li>
								<li><a href="#" class="ct_item"><i class="uil uil-chart-line"></i>Examenes</a></li>
								<!-- <li><a href="#" class="ct_item"><i class="uil uil-book-open"></i>Simuladores</a></li> -->
							</ul>
						</div>
						<!-- <div class="fcrse_3">
							<div class="cater_ttle">
								<h4>Artículos Relacionados</h4>
							</div>
							<ul class="allcate15">
								<li><a href="#" class="ct_item">Instructor Revenue Share</a></li>
								<li><a href="#" class="ct_item">Coupons & Course Referral Links: Rules and Guidelines</a></li>
								<li><a href="#" class="ct_item">Rules and Guidelines</a></li>
								<li><a href="#" class="ct_item">Instructor Promotional Agreements and Cursus Deals</a></li>
								<li><a href="#" class="ct_item">Cursus Coupons: FAQ</a></li>
								<li><a href="#" class="ct_item">How to Select Your Payout Method And Become a Premium Instructor</a></li>
							</ul>
						</div>
						<a href="#" class="rqst152">Submit A Request</a> -->
					</div>
					<div class="col-lg-9 col-md-7">
						<div class="vew120 frc123">
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur volutpat maximus pellentesque. Integer sem enim, luctus at nibh at, condimentum sagittis sapien. Sed tempus ipsum erat, sit amet efficitur velit interdum eu. Vestibulum hendrerit id dolor eu scelerisque. Phasellus ex dui, consequat nec feugiat eu, dapibus eget ante. Sed sodales interdum dui, at euismod mi feugiat hendrerit. Suspendisse auctor libero in tempor mollis. Nulla et dolor velit. Aliquam sit amet luctus quam.</p>
						</div>
						<div class="vew120 mt-35 mb-30">			
							<h4>Pagos</h4>
							<p>Nam a egestas libero, eget eleifend turpis. Sed id ipsum a ipsum aliquam laoreet sit amet sit amet nibh. Proin dapibus, libero sed posuere rhoncus, orci mi cursus enim, at accumsan eros massa lacinia mi. Nunc eget finibus felis, volutpat malesuada sem. Aliquam ac nisl pellentesque, varius neque sit amet, porttitor nunc. Nullam elit tellus, dapibus non eleifend sed, hendrerit eget velit. Aliquam ut felis dictum, tincidunt magna vitae, aliquam massa. In porttitor tristique quam, non dignissim sapien pharetra ultrices. Cras non ante non velit mollis mollis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Quisque et bibendum urna, eget consequat sapien. Integer sed condimentum nibh. Integer id neque tristique, lobortis massa ac, dapibus nibh. Donec nulla odio, porttitor ac rutrum eget, volutpat a velit. Curabitur et enim quis diam congue dictum et vitae dui. Nulla tortor orci, luctus a pretium vel, ultrices porta nisl.</p>
							<p class="mt-4">Etiam lobortis dictum tincidunt. Interdum et malesuada fames ac ante ipsum primis in faucibus. Suspendisse ultricies efficitur dui, suscipit tempus elit condimentum quis. Duis sed vestibulum tortor, eget cursus odio.</p>
						</div>
						<div class="vew120 mt-35 mb-30">			
							<h4>Como pagar con un cupon si eres estudiante presencial</h4>
							<ul class="ntt125 mtl145">
								<li><p>Solicita tu cupon</p></li>
								<li><p>Ir a la sección de tu perfíl</p></li>								
								<li><p>Actualizar tu inscripción</p></li>
								<li><p>Seleccionar el tipo de pago</p></li>
								<li><p>Ingresar tu codigo y continuar con las instrucciones</p></li>														
							</ul>
						</div>
						<div class="vew120 mt-35 mb-30">			
							<h4>Porque no compartir tu cupon</h4>
							<p>Morbi lectus nunc, lacinia ut consequat a, semper vel erat. Duis ut lacus nec dui sodales mattis. Mauris tellus dolor, pulvinar sit amet pretium a, malesuada sed tellus. Aliquam ultrices elit neque, quis lacinia ex porttitor non. Donec ac iaculis turpis. Nulla lacinia enim quis orci aliquam, non cursus urna pellentesque. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Phasellus in mi a nisi auctor interdum. Vivamus faucibus magna sed elit interdum consequat. Vestibulum eu tortor vel ante feugiat faucibus quis et urna. Quisque interdum ac enim eu tempus. Fusce viverra, lectus egestas tincidunt cursus, tortor sapien convallis metus, vitae auctor risus metus vel nisi. Aenean dapibus bibendum mauris ut iaculis.</p>
						</div>
						<div class="vew120 mt-35 mb-30">			
							<h4>Cuando utilizar tu cupon</h4>
							<p>Quisque et bibendum urna, eget consequat sapien. Integer sed condimentum nibh. Integer id neque tristique, lobortis massa ac, dapibus nibh. Donec nulla odio, porttitor ac rutrum eget, volutpat a velit. Curabitur et enim quis diam congue dictum et vitae dui. Nulla tortor orci, luctus a pretium vel, ultrices porta nisl.</p>
						</div>
						<!-- <div class="rpt100 mt-35 mb-20">
							<span>Was this review helpful?</span>
							<div class="radio--group-inline-container">
								<div class="radio-item">
									<input id="radio-1" name="radio" type="radio">
									<label for="radio-1" class="radio-label">Yes</label>
								</div>
								<div class="radio-item">
									<input id="radio-2" name="radio" type="radio">
									<label for="radio-2" class="radio-label">No</label>
								</div>
							</div>
						</div> -->
					</div>
				</div>
			</div>
		</div>

      <footer-view></footer-view>
    </div>
    <!-- Body End -->
  </div>
</template>
