import BaseApi from '../../../infraestructure/BaseApi';

export default class ApiInstructors extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    findByAccount(userId) {
        return this.client.get(`${this.endPoint}/${userId}/by-account`)
    }

    doChangeStatus(data) {
        return this.client.put(`${this.endPoint}/do-change-status`, data)
    }
}