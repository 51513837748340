import BaseComponent from '../../../core/infraestructure/BaseComponent';
import MainMenuViewModel from '../../../core/features/shared/viewmodels/MainMenuViewModel';

export default {
  extends: BaseComponent,
  data() {
    return {
      viewModel: null
    };
  },
  created() {
    this.viewModel = new MainMenuViewModel(this)
    this.viewModel.bindMainMenu()
  },
  methods: {
    onCanShowModule(moduleName) {
      return this.canShow(moduleName)
    },
  },
};