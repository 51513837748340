export default class HighSchool {
    constructor(options) {
        Object.assign(this, options)
    }

    toSend() {
        return {
            id: this.id,
            code: this.code,
            name: this.name
        }
    }
}