import BaseApi from '../../../infraestructure/BaseApi';

export default class ApiPosts extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    published(criteria) {
        const parameters = (criteria) ? `?${criteria}` : ''
        return this.client.get(`${this.endPoint}/published${parameters}`)
    }

    findDetails(id) {
        return this.client.get(`${this.endPoint}/details/${id}`)
    }
}