import { EventBus } from '../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../core/infraestructure/ItemComponent';
import SimulatorStudentDetailViewModel from '../../../core/features/simulator/viewmodels/SimulatorStudentDetailViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            viewModel: null,
            simulatorResult: null,
            isLoading: true,
        };
    },
    props: {
        simulatorId: {
            required: true,
            type: String
        }
    },
    mounted() {
        // EventBus.$on('onChangeQuestion', this.onChangeQuestion);
    },
    beforeDestroy() {
        // EventBus.$off('onChangeQuestion', this.onChangeQuestion);
    },
    created() {
        this.viewModel = new SimulatorStudentDetailViewModel(this);
        this.onActive()
    },
    methods: {
        onActive() {
            this.viewModel.findSimulatorResult(this.simulatorId)
        },
        onFindSimulatorResultResponse(response) {
            setTimeout(() => {
                this.simulatorResult = response.data
            }, 400);
            setTimeout(() => {
                this.isLoading = false
            }, 400);
        },
        onFinish() {
            this.isLoading = true
            setTimeout(() => {
                this.isLoading = false
                this.simulatorResult = null
            }, 800);
            setTimeout(() => {
                this.$router.push('/estudiante/resumen')
            }, 810);
        },
        getStatusTag(percentage) {
            return this.viewModel.getStatusTag(percentage)
        }
    },
};