import _ from 'lodash';
import BaseViewModel from '../../../infraestructure/BaseViewModel';
import UrlRender from '../../../shared/helpers/UrlRender';

export default class AccountConfirmationViewModel extends BaseViewModel {
  constructor (view) {
    super(view)
  }

  onConfirmAccount(data) {
    this.api.authentication.confirmAccount(data)
      .then(response => this.view.onConfirmAccountResponse(response.data))
      .catch(this.view.onConfirmAccountError)
  }

  getUrlParameter(key) {
    return UrlRender.getUrlParameter(key);
  }
  
}