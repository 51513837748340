import _ from 'lodash'

export default {
  permissionKey: 'permissions',

  setPermissions (permissions) {
    if (window.localStorage) {
      window.localStorage.setItem(this.permissionKey, JSON.stringify(permissions))
    } else {
      // eslint-disable-next-line no-undef
      throw new Exception('Su navegador no soporta este tipo de autenticación, favor de actualizarlo.')
    }
  },

  getPermissions () {
    if (window.localStorage) {
      return JSON.parse(window.localStorage.getItem(this.permissionKey))
    } else {
      // eslint-disable-next-line no-undef
      throw new Exception('Su navegador no soporta este tipo de autenticación, favor de actualizarlo.')
    }
  },

  removePermissions () {
    if (!window.localStorage) return null
    window.localStorage.removeItem(this.permissionKey)
  },

  canShow (moduleName, permissions) {
    return _.includes(permissions, `Permission.${moduleName}.Show`)
  },
  canCreate (moduleName, permissions) {
    return _.includes(permissions, `Permission.${moduleName}.Create`)
  },
  canDelete (moduleName, permissions) {
    return _.includes(permissions, `Permission.${moduleName}.Delete`)
  },
  canUpdate (moduleName, permissions) {
    return _.includes(permissions, `Permission.${moduleName}.Update`)
  },
  canRead (moduleName, permissions) {
    return _.includes(permissions, `Permission.${moduleName}.Read`)
  },
  canAllow (moduleName, permissions, feature) {
    if (feature) {
      return _.includes(permissions, `allow-${feature}-${moduleName}`)
    } else {
      return _.includes(permissions, `allow-${moduleName}`)
    }
  }
}
