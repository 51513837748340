<script>
import ModalStudentAccountSubscriptionComponent from '../features/ModalStudentAccountSubscriptionComponent';
export default {
  extends: ModalStudentAccountSubscriptionComponent,
  name: 'ModalStudentAccountSubscriptionView',
};
</script>

<template>
  <el-dialog
    title="Suscripción"
    custom-class="subscription-account-modal"
    :visible.sync="conf.isVisible"
    width="40%"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <el-row v-if="formData" v-loading="isLoading">
      <el-col :span="24">
        <div class="course_tabs_1 subscription">
          <div id="add-course-tab" class="step-app">
            <ul class="step-steps">
              <li class="active">
                <a href="#tab_step1">
                  <span class="number"></span>
                  <span class="step-name">PLANES</span>
                </a>
              </li>
              <li>
                <a href="#tab_step2">
                  <span class="number"></span>
                  <span class="step-name">PAGO</span>
                </a>
              </li>
              <li>
                <a href="#tab_step3">
                  <span class="number"></span>
                  <span class="step-name">RESUMEN</span>
                </a>
              </li>
            </ul>
            <div class="step-content">
              <div class="step-tab-panel step-tab-info active" id="tab_step1">
                <div class="tab-from-content">
                  <div class="title-icon">
                    <h3 class="title">
                      <i class="uil uil-info-circle"></i>Planes de Subscripci&oacute;n
                    </h3>
                  </div>
                  <fieldset>
                    <el-collapse accordion>
                      <el-collapse-item
                        :name="plan.id"
                        v-for="plan in subscriptionPlans"
                        v-bind:key="plan.id"
                      >
                        <template slot="title">
                          <el-radio v-model="planSelected" :label="plan.id">{{plan.name}} <i class="header-icon el-icon-information"></i></el-radio>
                        </template>
                        <el-row>
                          <el-col :span="20" :xs="24">
                            <div class="plan-description">
                              <p>
                                {{ plan.description }}
                              </p>
                            </div>
                          </el-col>
                          <el-col :span="4" :xs="24">
                            <div class="plan-amount">
                              <p>PRECIO</p>
                              {{ formatMoney(plan.amount) }}
                            </div>
                          </el-col>
                        </el-row>
                      </el-collapse-item>
                    </el-collapse>
                  </fieldset>
                </div>
              </div>

              <div class="step-tab-panel step-tab-gallery" id="tab_step2">
                <div class="tab-from-content">
                  <div class="title-icon">
                    <h3 class="title">
                      <i class="uil uil-money-withdrawal"></i>Forma de Pago
                    </h3>
                  </div>
                  <fieldset>
                    <el-tabs v-model="paymentMethodType">
                      <el-tab-pane label="Código de Descuento" name="subscriptionCode">
                        <el-input type="text" size="medium" v-model="discountCode" placeholder="Ingresar Código de Descuento"></el-input>
                      </el-tab-pane>
                      <!-- <el-tab-pane label="Tarjeta" name="subscriptionCard">Tarjeta</el-tab-pane>
                      <el-tab-pane label="Oxxo" name="subscriptionOxxo">Oxxo</el-tab-pane> -->
                    </el-tabs>
                  </fieldset>
                </div>
              </div>

              <div class="step-tab-panel step-tab-gallery" id="tab_step3">
                <div class="tab-from-content">
                  <div class="title-icon">
                    <h3 class="title">
                      <i class="uil uil-money-withdrawal"></i>Detalles de suscripci&oacute;n
                    </h3>
                  </div>
                  <fieldset>
                    <el-row type="flex" class="row-bg subscription-details" :gutter="20">
                      <el-col :span="24">
                        <h3>Metodo de Pago</h3>
                        <p>{{getPaymentMethodName()}}</p>
                      </el-col>
                    </el-row>
                     <el-row type="flex" class="row-bg subscription-details" :gutter="20">
                      <el-col :span="24">
                        <h3>Plan de Suscripci&oacute;n</h3>
                        <p>{{getSubscriptionPlanMethodName()}}</p>
                      </el-col>
                    </el-row>
                    <el-row v-if="this.discountCode" type="flex" class="row-bg subscription-details" :gutter="20">
                      <el-col :span="24">
                        <h3>Código de Descuento</h3>
                        <p>{{this.discountCode}}</p>
                      </el-col>
                    </el-row>
                    <el-row v-if="this.subscriptionDetails" type="flex" class="row-bg subscription-details" :gutter="20">
                      <el-col :span="24">
                        <i class="uil uil-check-circle"></i>
                      </el-col>
                    </el-row>
                    <el-row v-if="this.subscriptionDetails" type="flex" class="row-bg subscription-details" :gutter="20">
                      <el-col :span="24">
                        <h3>Duración de la Suscripci&oacute;n</h3>
                        <p>{{this.subscriptionDetails.subscriptionDate | formatDate}} - {{this.subscriptionDetails.subscriptionEndDate | formatDate}}</p>
                      </el-col>
                    </el-row>
                  </fieldset>
                </div>
              </div>
            </div>

            <div class="step-footer step-tab-pager">
              <button
                data-direction="prev"
                class="btn btn-default steps_btn btn-primary"
                v-if="onHideBackButton()">
                Anterior
              </button>
              <button
                data-direction="next"
                class="btn btn-default steps_btn btn-primary"
              v-if="onHideBackButton()">
                Siguiente
              </button>
              <button
                data-direction="finish"
                class="btn btn-default steps_btn btn-primary"
                @click="onPaySubscription"
                v-if="onHideBackButton()"
              >
                Pagar
              </button>
              <div v-if="onShowFinishButton()" style="width:100%;">
                <button
                  class="btn btn-default steps_btn btn-primary finish" @click="onSubscriptionFinish">
                Finalizar
              </button>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-dialog>
</template>
