import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ListComponent from '../../../../core/infraestructure/ListComponent';
import AdvisoryCityViewModel from '../../../../core/features/setting/viewmodels/AdvisoryCityViewModel';

export default {
  extends: ListComponent,
  data() {
    return {
      modulePermissions: 'Settings-AdvisoryCities',
      item: null,
      searchName: null,
      searchCode: null,
      viewModel: null,
      parentFilter: '',
      parents: [],
      modalImport: {
        isVisible: false,
        isLoading: false,
        title: 'IMPORTAR CIUDAD DE ASESORIAS',
        onUploadImport: 'onUploadImportAdvisoryCity',
        onCancelImport: 'onCancelImportAdvisoryCity',
      },
    };
  },
  mounted() {
    EventBus.$on('onSubmitedAdvisoryCity', this.onSearch);
    EventBus.$on('onUploadImportAdvisoryCity', this.onUploadImport);
    EventBus.$on('onCancelImportAdvisoryCity', this.onCancelImport);
  },
  beforeDestroy() {
    EventBus.$off('onSubmitedAdvisoryCity', this.onSearch);
    EventBus.$off('onUploadImportAdvisoryCity', this.onUploadImport);
    EventBus.$off('onCancelImportAdvisoryCity', this.onCancelImport);
  },
  created() {
    this.viewModel = new AdvisoryCityViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      //Hola
      this.onSearch();
    },
    onAllFilterChange(data, field) {
      this.onFilterChange(data, field);
    },
    onSearchCourseCategory(data) {
      if (data) {
        this.viewModel.findCourseCategories(`Name=${data}`)
    } else {
        this.parents = []
    }
    },
    onSearchCourseCategoriesResponse(response) {
      this.parents = response;
    },
    onFocusCourseCategory() {
      this.$forceUpdate()
    },
    onShowImport() {
      this.viewModel.showModal(this.modalImport, true);
    },
    onUploadImport(file){
      this.viewModel.showModalLoading(this.modalImport, true);
      this.viewModel.import(file);
    },
    onCancelImport(){
      this.viewModel.showModal(this.modalImport, false);
    },
    onExportToExcel() {
      this.viewModel.exportToExcel();
    },
    onCanCreate() {
      return this.canCreate(this.modulePermissions)
    },
    onCanRead() {
      return this.canRead(this.modulePermissions)
    },
    onCanUpdate() {
      return this.canUpdate(this.modulePermissions)
    },
    onCanDelete() {
      return this.canDelete(this.modulePermissions)
    },
  },
  computed: {}
}