export default class Post {
    constructor(options) {
      Object.assign(this, options)
    }
  
    toSend() {
      return {
        id: this.id,
        title: this.title,
        description: this.description,
        imageUrl: this.imageUrl,
        isPublished: this.isPublished,
      }
    }
  }