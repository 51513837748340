<script>
import ModalPostComponent from '../../features/modals/ModalPostComponent';
export default {
  extends: ModalPostComponent,
  name: 'ModalPostView',
};
</script>

<template>
  <el-dialog title="Publicación" :visible.sync="conf.isVisible" width="30%" custom-class="post-modal" :before-close="onCancel" :close-on-click-modal="false"
  >
    <el-form :model="formData" :rules="rules" ref="formData" status-icon :label-position="'top'" v-loading="conf.isLoading">
      <fieldset>
        <legend>Información de Publicación</legend>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-upload
              action
              :show-file-list="false"
              :on-success="onSuccessHandlerPostPicture"
              :before-upload="onBeforeUploadHandlerPostPicture" class="avatar">
              <img v-if="postImageUrl" :src="postImageUrl">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-col>
        </el-row>
        <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" align="center">
            <el-form-item label="Publicar" prop="isPublished">
              <el-switch
                style="display: block"
                v-model="formData.isPublished"
                active-color="#13ce66"
                inactive-text="No"
                active-text="Si"
                :inactive-value="false"
                :active-value="true"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
         <el-row class="row-bg" :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item label="Titulo" prop="title">
              <el-input v-model="formData.title" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Descripción" prop="tags">
              <el-input type="textarea" class="description-editor"
                  :row="4"
                  maxlength="255"
                  size="medium"
                  v-model="formData.description"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </fieldset>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel" size="small" class="btn-cancel">Cancelar</el-button>
      <el-button
        type="primary"
        @click="onSubmitForm('formData')"
        :disabled="!onCanSaveOrUpdate()"
        class="btn-primary"
        size="small"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>