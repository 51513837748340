import BaseViewModel from '../../../infraestructure/BaseViewModel'

export default class NotificationAlertsViewModel extends BaseViewModel {
  constructor (view) {
    super(view)
  }
  
  bindNotificationAlerts() {
      this.api.notifications.findAlerts()
        .then((response) => this.view.onFindNotificationAlertsResponse(response.data))
        .catch(this.view.onError)
  }

  findResponseAnswerActivity(id) {
    this.api.responseActivityAnswers.findOne(id)
    .then((response) => this.view.onFindResponseAnswerActivityResponse(response.data.data))
    .catch(this.view.onError)
  }

  doMarkAsRead(data) {
    this.api.notifications.doMarkAsRead(data.id)
      .then((response) => this.view.onMarkAsReadResponse(response.data.data))
      .catch(this.view.onError)
  }

  isStudentActivity(value) {
    return _.includes(value, '/estudiante/actividades/')
  }

  getStudentActivityId(value) {
    var collection = value.split('/')
    return collection[3]
  }

}