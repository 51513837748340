import BaseComponent from "../../../../core/infraestructure/BaseComponent";
import DashboardStudentViewModel from '../../../../core/features/home/viewmodels/DashboardStudentViewModel'

export default {
    extends: BaseComponent,
    data() {
        return {
            viewModel: null,
            formProcessingMessage: "",
            isFormProcessing: false,
            currentCourses: [],
            finishedCourses: [],
            currentStats: null,
            formData: {
                email: ''
            },
            studentInfoItem: null,
            modalSubscriptionInfo: {
                isVisible: false,
                isLoading: false,
            },
            studentSimulatorItem: null,
            modalSimulatorInfo: {
                isVisible: false,
                isLoading: false,
            },
            rules: {
                /* email: [
                    { required: true, message: 'Correo electrónico requerido.', trigger: 'blur,change' },
                    { type: 'email', message: 'Correo electrónico no valido.', trigger: 'blur,change' }
                ], */
            }
        };
    },
    created() {
        this.viewModel = new DashboardStudentViewModel(this);
        this.onActive();
    },
    methods: {
        onActive() {
            this.viewModel.bindStats()
            this.$root.$on('update-session', this.onUpdateSession);
        },
        getCoverImage(url) {
            return (url) ? url : '/images/courses/add_img.jpg'
        },
        onFindCurrentCoursesResponse(response) {
            this.currentCourses = response.data;
            this.viewModel.bindCarousel('.featured_courses')
            
        },
        onFindFinishedCoursesResponse(response) {
            this.finishedCourses = response.data;
            this.viewModel.bindCarousel('.finished_courses')
        },
        onFindStatsResponse(response){
            this.currentStats = response.data;
        },
        mapTags(tags) {
            var collection = (tags) ? tags.split(',') : []
            return collection.join(' | ')
        },
        onDoLike(course) {
            this.viewModel.doLike(course.id)
        },
        onDoLikeResponse() {
            this.onSearch();
        },
        getSocialStatusClass(flag) {
            return (flag) ? 'uil uil-heart active' : 'uil uil-heart'
        },
        onCreateSimulator() {
            this.studentSimulatorItem = this.viewModel.createSimulator();
            this.viewModel.showModal(this.modalSimulatorInfo, true);
        },
        onUpdateSession(data){
            if(data.user.hasSubscriptions && !data.user.hasSubscriptionInfo){
                this.studentInfoItem = this.viewModel.onCreateStudentInfo()
                this.viewModel.showModal(this.modalSubscriptionInfo, true)
            }
        }
    },
    computed: {}
}