import { EventBus } from '../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../core/infraestructure/ItemComponent';
import SimulatorStudentDetailViewModel from '../../../core/features/simulator/viewmodels/SimulatorStudentDetailViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            viewModel: null,
            questionSelected: null,
            linkedList: null
        };
    },
    props: {
        currentSection: {
            required: true,
            type: Object
        },
        simulatorId: {
            required: true,
            type: String
        }
    },
    mounted() {
        // this.$root.$on('onSubmitedSimulator', this.onSubmitSimulator)
    },
    beforeDestroy() {
        // this.$root.$off('onSubmitedSimulator', this.onSubmitSimulator);
    },
    created() {
        this.viewModel = new SimulatorStudentDetailViewModel(this);
        this.onActive()
    },
    methods: {
        onActive() {
            this.linkedList = this.viewModel.initLinkedList(this.currentSection.simulatorQuestions);
            this.questionSelected = this.linkedList.get(0)
            if(this.questionSelected) {
                this.viewModel.changeQuestion({
                    simulatorId: this.simulatorId,
                    simulatorQuestionId: this.questionSelected.value.id
                })
            }
        },
        onNextQuestion() {
            if(this.questionSelected.next) {
                this.questionSelected = this.viewModel.nextNode(this.questionSelected)
                this.viewModel.changeQuestion({
                    simulatorId: this.simulatorId,
                    simulatorQuestionId: this.questionSelected.value.id
                })
            }
        },
        onPrevQuestion() {
            if (this.questionSelected.prev) {
                this.questionSelected = this.viewModel.prevNode(this.questionSelected)
                this.viewModel.changeQuestion({
                    simulatorId: this.simulatorId,
                    simulatorQuestionId: this.questionSelected.value.id
                })
            }
        },
        onChangeQuestionResponse(response) {
            EventBus.$emit('onChangeQuestion', response.data);
        },
        onFinishSection() {
            this.questionSelected =  null
            EventBus.$emit('onFinishSection', null);
        },
        isFinalQuestion() {
            return (!this.questionSelected.next)
        }
    },
};