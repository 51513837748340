import BaseComponent from "../../../core/infraestructure/BaseComponent";
import AccountConfirmationViewModel from '../../../core/features/authentication/viewmodels/AccountConfirmationViewModel.js';

export default {
    extends: BaseComponent,
    data() {
        return {
            viewModel: null,
            formProcessingMessage: "",
            isFormProcessing: false,
            formData: {
                email: ''
            },
            rules: {
                /* email: [
                    { required: true, message: 'Correo electrónico requerido.', trigger: 'blur,change' },
                    { type: 'email', message: 'Correo electrónico no valido.', trigger: 'blur,change' }
                ], */
            }
        };
    },
    created() {
        this.viewModel = new AccountConfirmationViewModel(this);
        this.onActive();
    },
    methods: {
        onActive() {
            var token = this.viewModel.getUrlParameter('token');
            if (token) {
                this.formData.token = token
                this.viewModel.showFormLoading(true, 'Confirmando cuenta...');
                this.viewModel.onConfirmAccount(this.formData);
            } else {
                this.$router.push('/')
            }
        },
        onSend(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.viewModel.showFormLoading(true, 'Confirmando cuenta...');
                    this.viewModel.onConfirmAccount(this.formData);
                    return true;
                }
                return false;
            });
        },
        onConfirmAccountResponse(response) {
            this.viewModel.showFormLoading(false, '')
            this.viewModel.showNotification('success', 'Completo', response.message);
            /* setTimeout(() => {
                this.$router.push("/");
            }, 2000); */
        },
        onConfirmAccountError(error) {
            this.viewModel.showFormLoading(false, '');
            this.onError(error);
        },
        onGoToLogin() {
            this.$router.push("/");
        }

    },
    computed: {}
};