import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ListComponent from '../../../../core/infraestructure/ListComponent';
import SubscriptionViewModel from '../../../../core/features/subscription/viewmodels/SubscriptionViewModel';

export default {
  extends: ListComponent,
  data() {
    return {
      modulePermissions: 'SubscriptionsContent-Subscriptions',
      item: null,
      searchMax: null,
      searchAmount: null,
      subscriptionPlans: [],
      discounts: [],
      viewModel: null,
      filterOptions: {
        folioFilter: '',
        fullNameFilter: '',
        subscriptionPlanFilter: '',
        discountFilter: '',
        subscriptionDate: null,
        closuseDate: null,
        searchAmount: null,
      },
      modalImport: {
        isVisible: false,
        isLoading: false,
        title: 'IMPORTAR INSCRIPCIONES',
        onUploadImport: 'onUploadImportSubscription',
        onCancelImport: 'onCancelImportSubscription'
      }
    }
  },
  mounted() {
    EventBus.$on('onSubmitedSubscription', this.onSearch);
    EventBus.$on('onUploadImportSubscription', this.onUploadImport);
    EventBus.$on('onCancelImportSubscription', this.onCancelImport);
  },
  beforeDestroy() {
    EventBus.$off('onSubmitedSubscription', this.onSearch);
    EventBus.$off('onUploadImportSubscription', this.onUploadImport);
    EventBus.$off('onCancelImportSubscription', this.onCancelImport);
  },
  created() {
    this.viewModel = new SubscriptionViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.onSearch();
    },
    onAllFilterChange(data, field) {
      this.onFilterChange(data, field);
    },
    onFilterDate(data, field) {
      this.viewModel.updateFilters(data, field, '=');
    },
    onRangeDate(data, startDate, endDate) {
      if(data) {
        this.onFilterDate(data[0], startDate);
        this.onFilterDate(data[1], endDate);
        this.onSearch();
      } else {
        this.viewModel.removeFilter(startDate);
        this.viewModel.removeFilter(endDate);
        this.onSearch();
      }
    },
    onSearchSubscriptionPlans(data) {
      if(data) {
          this.viewModel.findSubscriptionPlans(data)
      } else {
          this.subscriptionPlans = []
      }
    },
    onSearchSubscriptionPlansResponse(response) {
        this.subscriptionPlans = response;
    },
    onSearchDiscounts(data) {
      if (data) {
          this.viewModel.findDiscounts(`Name=${data}`)
      } else {
          this.discounts = []
      }
    },
    onSearchDiscountsResponse(response) {
        this.discounts = response;
    },
    onShowImport() {
      this.viewModel.showModal(this.modalImport, true);
    },
    onUploadImport(file){
      this.viewModel.showModalLoading(this.modalImport, true);
      this.viewModel.import(file);
    },
    onCancelImport(){
      this.viewModel.showModal(this.modalImport, false);
    },
    onExportToExcel() {
      this.viewModel.exportToExcel();
    },
    onCanCreate() {
      return this.canCreate(this.modulePermissions)
    },
    onCanRead() {
      return this.canRead(this.modulePermissions)
    },
    onCanUpdate() {
      return this.canUpdate(this.modulePermissions)
    },
    onCanDelete() {
      return this.canDelete(this.modulePermissions)
    },
    formatMoney(amount) {
      return this.viewModel.formatMoney(amount);
    },
    mapProfileImage(userProfile) {
      return (userProfile && userProfile.profileImageUrl) ? userProfile.profileImageUrl : '/images/left-imgs/img-1.jpg'
    }
  },
  computed: {}
}