import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';
import ResponseActivityAnswerViewModel from '../../../../core/features/responseactivityanswer/viewmodels/ResponseActivityAnswerViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'CourseContent-Courses',
            viewModel: null,
            permissions: null,
            modules: [],
            item: null,
            editorDescription: null,
            modalItem: {
                isVisible: false,
                isLoading: false
            },
            rules: {
                
            },
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new ResponseActivityAnswerViewModel(this)
        this.onActive()
    },
    methods: {
        onActive() {
            setTimeout(() => {
                this.viewModel.onBindEditor('.description-editor');
                this.viewModel.onBindFormView();
            }, 300);
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.formData.contentObservation = this.editorDescription.root.innerHTML;
                    this.viewModel.doReview(this.formData)
                }
            });
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onSaveResponse(response) {
            this.showLoading(false, '');
            this.onShowSuccess(response.message);
            this.showComponent(false);
            EventBus.$emit('onSubmitedResponseActivityAnswer', null);
        },
    }
}