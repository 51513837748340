import _ from 'lodash';
import BaseViewModel from '../../../infraestructure/BaseViewModel';
import Post from '../models/Post';

export default class PostViewModel extends BaseViewModel {
  constructor(view) {
    super(view)
  }
  // INICIALIZADORES

  //#region CRUD

  onEdit(data) {
    let item = new Post(data);
    item.state = "update";
    return item;
  }

  onSearchCollection() {
    this.view.limit = 5;
    return this.api.blogPages.published(this.getSearchQuery())
  }

  mapCollection(collection) {
    return _.map(collection, (data) => new Post(data));
  }

  onSearchItem(name) {
    this.onGetDetails(name)
      .then(response => this.onGetDetailsResponse(response.data))
      .catch(this.view.onError);
  }

  onGetDetails(name) {
    return this.api.blogPages.findDetails(name)
  }

  onGetDetailsResponse(response) {
    this.view.item = response.data;
    //Change in the url the id for the title
    //window.history.pushState(null, "", this.view.item.title.replace(/ /g, '-').toLowerCase());
  }
  
  onCancel(context) {
    context.view.item = null;
  }

  import(file) {
    this.api.blogPages.import(file)
      .then(response => this.view.onImportResponse(response.data))
      .catch(this.view.onError)
  }

  exportToExcel() {
    this.api.blogPages.exportToExcel()
      .then(response => this.view.onExportResponse(response.data))
      .catch(this.view.onError)
  }

  // #endregion

  // #region BINDING DATA

  bindFormView() {}

  
  // #endregion

  // #region QUERIES
  

  // #endregion

  //#region MAPPING DATA

  
  // #endregion

  // #region VALIDATIONS
  
  // #endregion
}