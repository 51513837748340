export default class Discount {
    constructor(options) {
      Object.assign(this, options)
    }
  
    getFullName(){
      return `${this.firstName} ${this.lastName}`
    }
  
    getPermissions(){
  
    }
  
    toSend() {
      console.log(this);
      return {
        id: this.id,
        code: this.code,
        name: this.name,
        startDate: this.reportDate[0],
        endDate: this.reportDate[1],
        amount: this.amount,
        max: this.max,
        subscriptionPlanId: this.subscriptionPlan.id
      }
    }
  }
  