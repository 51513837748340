import _ from 'lodash'
import BaseViewModel from '../../../infraestructure/BaseViewModel'

export default class DashboardViewModel extends BaseViewModel {
    constructor(view) {
        super(view)
    }

    bindStats() {
        this.api.dashboardMain.findCourseStats()
            .then((response) => {
                this.view.findCourseStatsResponse(response.data)
                return this.api.dashboardMain.findUserStats()
            })
            .then((response) => this.view.findUserStatsResponse(response.data))
            .catch(this.view.onError);
    }
}