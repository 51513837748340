import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ListComponent from '../../../../core/infraestructure/ListComponent';
import LiveClassViewModel from '../../../../core/features/liveclass/viewmodels/LiveClassViewModel';

export default {
  extends: ListComponent,
  data() {
    return {
      modulePermissions: 'LiveClassContent-LiveClass',
      item: null,
      viewModel: null,
      courses: [],
      finishedStatuses: [],
      filterOptions: {
        courseFilter: '',
        isFinishedFilter: '',
        titleFilter: '',
      },
      modalImport: {
        isVisible: false,
        isLoading: false,
        title: 'IMPORTAR CLASES EN VIVO',
        onUploadImport: 'onUploadImportLiveClass',
        onCancelImport: 'onCancelImportLiveClass'
      }
    }
  },
  mounted() {
    EventBus.$on('onSubmitedLiveClass', this.onSearch);
    EventBus.$on('onUploadImportLiveClass', this.onUploadImport);
    EventBus.$on('onCancelImportLiveClass', this.onCancelImport);
  },
  beforeDestroy() {
    EventBus.$off('onSubmitedLiveClass', this.onSearch);
    EventBus.$off('onUploadImportLiveClass', this.onUploadImport);
    EventBus.$off('onCancelImportLiveClass', this.onCancelImport);
  },
  created() {
    this.viewModel = new LiveClassViewModel(this);
    this.onSearch();
  },
  methods: {
    onSearch() {
      this.viewModel.findLiveClassesByPublisher();
      this.onSearchFinishedStatus();
    },
    findLiveClassesByPublisherResponse(response) {
        this.collection = response;
    },
    onAllFilterChange(data, field) {
      this.onFilterChange(data, field);
    },
    onFilterDate(data, field) {
      this.viewModel.updateFilters(data, field, '=');
    },
    onRangeDate(data, startDate, endDate) {
      if(data) {
        this.onFilterDate(data[0], startDate);
        this.onFilterDate(data[1], endDate);
        this.onSearch();
      } else {
        this.viewModel.removeFilter(startDate);
        this.viewModel.removeFilter(endDate);
        this.onSearch();
      }
    },
    onSearchCourse(data) {
      if (data) {
        this.viewModel.findCourses(`title=${data}`)
    } else {
        this.courses = []
    }
    },
    onSearchCoursesResponse(response) {      
      this.courses = response;
    },
    onFocusCourse() {
      this.$forceUpdate()
    },
    onSearchFinishedStatus() {
      this.viewModel.findFinishedStatuses()
    },
    onSearchFinishedStatusesResponse(response) {
      this.finishedStatuses = response;
    },
    onUpdateFinished(liveClassId, isFinished) {
      this.viewModel.doFinish(liveClassId, isFinished)
    },
    onDoFinishResponse(response) {
      this.onSearch();
    },
    onShowImport() {
      this.viewModel.showModal(this.modalImport, true);
    },
    onUploadImport(file){
      this.viewModel.showModalLoading(this.modalImport, true);
      this.viewModel.import(file);
    },
    onCancelImport(){
      this.viewModel.showModal(this.modalImport, false);
    },
    onExportToExcel() {
      this.viewModel.exportToExcel();
    },
    onCanCreate() {
      return this.canCreate(this.modulePermissions)
    },
    onCanRead() {
      return this.canRead(this.modulePermissions)
    },
    onCanUpdate() {
      return this.canUpdate(this.modulePermissions)
    },
    onCanDelete() {
      return this.canDelete(this.modulePermissions)
    },
    onWatch(id) {
      this.$router.push(`en-vivo/${id}/detalles`)
    }
  },
  computed: {}
}