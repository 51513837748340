<script>
import ModalAdvisoryCityComponent from '../../features/modals/ModalAdvisoryCityComponent';
export default {
  extends: ModalAdvisoryCityComponent,
  name: 'ModalAdvisoryCityView',
};
</script>

<template>
  <el-dialog
    title="Ciudad de Asesoría"
    :visible.sync="conf.isVisible"
    width="30%"
    custom-class="advisory-city-modal"
    :before-close="onCancel"
    :close-on-click-modal="false"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      status-icon
      :label-position="'top'"
      v-loading="conf.isLoading"
    >
      <fieldset>
        <legend>Información de Ciudad de Asesoría</legend>
         <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Código" prop="code">
              <el-input v-model="formData.code" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
         <el-row type="flex" class="row-bg" :gutter="20">
          <el-col :span="24">
            <el-form-item label="Nombre" prop="name">
              <el-input v-model="formData.name" maxlength="70" size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </fieldset>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel" size="small" class="btn-cancel"
        >Cancelar</el-button
      >
      <el-button
        type="primary"
        @click="onSubmitForm('formData')"
        :disabled="!onCanSaveOrUpdate()"
        class="btn-primary"
        size="small"
        >Guardar</el-button
      >
    </span>
  </el-dialog>
</template>