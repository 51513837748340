import ListComponent from '../../../../core/infraestructure/ListComponent';
import LiveClassViewModel from '../../../../core/features/liveclass/viewmodels/LiveClassViewModel';

export default {
    extends: ListComponent,
    data() {
        return {
            viewModel: null,
            modalItemLiveClass: {
                isVisible: false,
            },
            itemLiveClass: null,
            limit: 12,
            isSubscribed: false,
            studentInfoItem: null,
            modalSubscriptionInfo: {
                isVisible: false,
                isLoading: false,
            },
        };
    },
    created() {
        this.viewModel = new LiveClassViewModel(this);
        this.onActive();
    },
    methods: {
        onActive() {
            this.viewModel.onBindProfile()
            this.viewModel.findLiveClassesFinished()
            this.$root.$on('update-session', this.onUpdateSession);
        },
        findLiveClassesFinishedResponse(response) {
            this.collection = response;
        },
        mapProfileImageUrl(item) {
            return (item.publisher && item.publisher.profileImageUrl) ? item.publisher.profileImageUrl : '/images/left-imgs/img-1.jpg';
        },
        onShowLiveClass(item) {
            if (this.isSubscribed) {
                this.itemLiveClass = item;
                this.viewModel.showModal(this.modalItemLiveClass, true);
            } else {
                this.$alert('Para ver el contenido de nuestros cursos, necesitas una subscripción activa.', 'Información', {
                    confirmButtonText: 'Aceptar',
                    callback: action => {
                        this.$router.push(`/estudiante/mi-cuenta`)
                    },
                    confirmButtonClass: 'btn-cancel'
                });
            }
        },
        onUpdateSession(data){
            if(data.user.hasSubscriptions && !data.user.hasSubscriptionInfo){
                this.studentInfoItem = this.viewModel.onCreateStudentInfo()
                this.viewModel.showModal(this.modalSubscriptionInfo, true)
            }
        },
        isCollectionEmpty(collection) {
            return _.isEmpty(collection)
        }
    }
}