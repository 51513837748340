<script>
import AccountConfirmationComponent from '../features/AccountConfirmationComponent';

export default {
  name: 'AccountConfirmationView',
  mixins: [AccountConfirmationComponent],
  components: { },
};
</script>

<template>
    <!-- Signup Start -->
	<div class="sign_in_up_bg">
		<div class="container">
			<div class="row justify-content-lg-center justify-content-md-center">
				<div class="col-lg-12">
					<div class="main_logo25" id="logo">
						<a href="index.html"><img class="logo-login" src="images/logo.png" alt=""></a>
						<a href="index.html"><img class="logo-inverse" src="images/ct_logo.svg" alt=""></a>
					</div>
				</div>
			
				<div class="col-lg-6 col-md-8">
					<div class="sign_form">
						<h2>Confirmando cuenta</h2>
						<p>Accesa y comienza a aprender.</p>
						<el-form :model="formData" :rules="rules" ref="formData" status-icon :label-position="'left'" v-loading="isFormProcessing" :element-loading-text="formProcessingMessage">
							
							<button class="login-btn btn-primary" type="button" @click="onGoToLogin('formData')">Ingresar</button>
						</el-form>
						<p class="sgntrm145">Registrandote, estas de acuerdo con nuestros <a href="terms_of_use.html">T&eacute;rminos de Uso</a> y <a href="terms_of_use.html">Pol&iacute;ticas de Privacidad</a>.</p>
					</div>
					<div class="sign_footer"><img src="/images/logo_no_text.png" alt="" class="logo_footer">© 2020 <strong>AppMisionate</strong>. Todos los Derechos Reservados.</div>
				</div>				
			</div>				
		</div>				
	</div>
	<!-- Signup End -->	
</template>