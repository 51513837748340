import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ItemComponent from '../../../../core/infraestructure/ItemComponent';
import SubscriptionViewModel from '../../../../core/features/subscription/viewmodels/SubscriptionViewModel';

export default {
    extends: ItemComponent,
    data() {
        return {
            modulePermissions: 'SubscriptionsContent-Subscriptions',
            viewModel: null,
            permissions: null,
            modules: [],
            users: [],
            subscriptionPlans: [],
            discounts: [],
            rules: {
                code: [
                    { required: true, message: 'Código requerido.', trigger: 'blur' }
                ],
                amount: [
                    { required: true, message: 'Precio requerido.', trigger: 'blur' }
                ],
                userProfile: [
                    { required: true, message: "Selecciona un Usuario", trigger: "change" }
                ],
                subscriptionPlan: [
                    { required: true, message: "Selecciona un Plan", trigger: "change" }
                ],
                reportDate: [
                    { required: true, message: "Fecha requerida.", trigger: "change" }
                ],
            }
        }
    },
    props: {
        conf: {
            type: Object,
            required: true
        },
        formData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.viewModel = new SubscriptionViewModel(this)
        this.onActive()
    },
    mounted() { },
    methods: {
        onActive() {
            this.viewModel.bindFormView();
        },
        onSubmitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.onSave(this.formData)
                }
            });
        },
        onCanSaveOrUpdate() {
            return this.canCreate(this.modulePermissions) || this.canUpdate(this.modulePermissions)
        },
        onSaveResponse(response) {
            this.showLoading(false, '');
            this.onShowSuccess(response.message);
            this.showComponent(false);
            EventBus.$emit('onSubmitedSubscription', null);
        },
        onSearchUsers(data) {
            if (data) {
                this.viewModel.findUsers(`FullName=${data}`)
            } else {
                this.users = []
            }
        },
        onSearchUsersResponse(response) {
            this.users = response;
        },
        onSearchSubscriptionPlans(data) {
            if (data) {
                this.viewModel.findSubscriptionPlans(`Name=${data}`)
            } else {
                this.subscriptionPlans = []
            }
        },
        onSearchSubscriptionPlansResponse(response) {
            this.subscriptionPlans = response;
        },
        onSearchDiscounts(data) {
            if (data) {
                this.viewModel.findDiscounts(`Name=${data}`)
            } else {
                this.discounts = []
            }
        },
        onSearchDiscountsResponse(response) {
            this.discounts = response;
        },
        onFocusDate(){
            this.$forceUpdate()
        }
    }
}