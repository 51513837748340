<script>
import FooterView from '../../shared/views/MainFooterView';
export default {
  name: 'TermsPoliticsView',
  components: {
    'footer-view': FooterView,
  },
};
</script>

<template>
  <div>
    <!-- Header Start -->
    <header class="header clearfix">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="ml_item">
              <div class="main_logo main_logo15" id="website-logo">
                <router-link to="/">
                  <img src="/images/website_logo.png" alt="" />
                </router-link>
              </div>
            </div>
            <div class="header_right pr-0">
              <div class="back_link">
                <a href="/login" class="hde151">Ingresar</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- Header End -->
    <!-- Body Start -->
    <div class="wrapper _bg4586 _new89">
      <div class="_215cd2">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="course_tabs">
                <nav>
                  <div class="nav nav-tabs tab_crse  justify-content-center">
                    <a class="nav-item nav-link" href="/">Inicio</a>
                    <a class="nav-item nav-link" href="/acerca">Acerca</a>
                    <a class="nav-item nav-link" href="/noticias">Noticias</a>
                    <a class="nav-item nav-link" href="/contacto">Contacto</a>
                  </div>
                </nav>
              </div>
              <div class="title129 mt-35 mb-35">
                <h2>T&eacute;rminos y Condiciones</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="faq1256">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-5">
              <div class="fcrse_3 frc123">
                <ul class="ttrm15">
                  <li>
                    <a href="#" class="ct_item active">Términos de Uso</a>
                  </li>
                  <li>
                    <a href="#" class="ct_item">Políticas de Privacidad</a>
                  </li>
                  <li>
                    <a href="#" class="ct_item"
                      >Políticas de Derechos de Autor</a
                    >
                  </li>
                  <li><a href="#" class="ct_item">Políticas de Cookies</a></li>
                  <li>
                    <a href="#" class="ct_item">Términos de Asesor Educativo</a>
                  </li>
                  <li>
                    <a href="#" class="ct_item"
                      >Políticas de Precios e Incrementos</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-9 col-md-7">
              <div class="vew120 frc123">
                <div class="atlink">
                  Terminos de Uso actualizados Agosto 1, 2021.
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Curabitur volutpat maximus pellentesque. Integer sem enim,
                  luctus at nibh at, condimentum sagittis sapien. Sed tempus
                  ipsum erat, sit amet efficitur velit interdum eu. Vestibulum
                  hendrerit id dolor eu scelerisque. Phasellus ex dui, consequat
                  nec feugiat eu, dapibus eget ante. Sed sodales interdum dui,
                  at euismod mi feugiat hendrerit. Suspendisse auctor libero in
                  tempor mollis. Nulla et dolor velit. Aliquam sit amet luctus
                  quam.
                </p>
                <p class="mt-4">
                  Nam a egestas libero, eget eleifend turpis. Sed id ipsum a
                  ipsum aliquam laoreet sit amet sit amet nibh. Proin dapibus,
                  libero sed posuere rhoncus, orci mi cursus enim, at accumsan
                  eros massa lacinia mi. Nunc eget finibus felis, volutpat
                  malesuada sem. Aliquam ac nisl pellentesque, varius neque sit
                  amet, porttitor nunc. Nullam elit tellus, dapibus non eleifend
                  sed, hendrerit eget velit. Aliquam ut felis dictum, tincidunt
                  magna vitae, aliquam massa. In porttitor tristique quam, non
                  dignissim sapien pharetra ultrices. Cras non ante non velit
                  mollis mollis. Pellentesque habitant morbi tristique senectus
                  et netus et malesuada fames ac turpis egestas. Quisque et
                  bibendum urna, eget consequat sapien. Integer sed condimentum
                  nibh. Integer id neque tristique, lobortis massa ac, dapibus
                  nibh. Donec nulla odio, porttitor ac rutrum eget, volutpat a
                  velit. Curabitur et enim quis diam congue dictum et vitae dui.
                  Nulla tortor orci, luctus a pretium vel, ultrices porta nisl.
                </p>
                <p class="mt-4">
                  Etiam lobortis dictum tincidunt. Interdum et malesuada fames
                  ac ante ipsum primis in faucibus. Suspendisse ultricies
                  efficitur dui, suscipit tempus elit condimentum quis. Duis sed
                  vestibulum tortor, eget cursus odio.
                </p>
              </div>
              <div class="vew120 mt-35 mb-30">
                <h4>Tabla de Contenido</h4>
                <ul class="ntt125 mtl145">
                  <li><a href="#" class="term_link14">Cuentas</a></li>
                  <li>
                    <a href="#" class="term_link14">Pagos y Devoluciones</a>
                  </li>
                  <li>
                    <a href="#" class="term_link14"
                      >Reglas de Contenido y Comportamiento</a
                    >
                  </li>
                  <li><a href="#" class="term_link14">Como Contactarnos</a></li>
                </ul>
              </div>
              <div class="vew120 mt-35 mb-30">
                <h4>1. Cuentas</h4>
                <p>
                  Morbi lectus nunc, lacinia ut consequat a, semper vel erat.
                  Duis ut lacus nec dui sodales mattis. Mauris tellus dolor,
                  pulvinar sit amet pretium a, malesuada sed tellus. Aliquam
                  ultrices elit neque, quis lacinia ex porttitor non. Donec ac
                  iaculis turpis. Nulla lacinia enim quis orci aliquam, non
                  cursus urna pellentesque. Class aptent taciti sociosqu ad
                  litora torquent per conubia nostra, per inceptos himenaeos.
                  Phasellus in mi a nisi auctor interdum. Vivamus faucibus magna
                  sed elit interdum consequat. Vestibulum eu tortor vel ante
                  feugiat faucibus quis et urna. Quisque interdum ac enim eu
                  tempus. Fusce viverra, lectus egestas tincidunt cursus, tortor
                  sapien convallis metus, vitae auctor risus metus vel nisi.
                  Aenean dapibus bibendum mauris ut iaculis.
                </p>
              </div>
              <div class="vew120 mt-35 mb-30">
                <h4>2. Pagos y Devoluciones</h4>
                <p>
                  Morbi lectus nunc, lacinia ut consequat a, semper vel erat.
                  Duis ut lacus nec dui sodales mattis. Mauris tellus dolor,
                  pulvinar sit amet pretium a, malesuada sed tellus. Aliquam
                  ultrices elit neque, quis lacinia ex porttitor non. Donec ac
                  iaculis turpis. Nulla lacinia enim quis orci aliquam, non
                  cursus urna pellentesque. Class aptent taciti sociosqu ad
                  litora torquent per conubia nostra, per inceptos himenaeos.
                  Phasellus in mi a nisi auctor interdum. Vivamus faucibus magna
                  sed elit interdum consequat. Vestibulum eu tortor vel ante
                  feugiat faucibus quis et urna. Quisque interdum ac enim eu
                  tempus. Fusce viverra, lectus egestas tincidunt cursus, tortor
                  sapien convallis metus, vitae auctor risus metus vel nisi.
                  Aenean dapibus bibendum mauris ut iaculis.
                </p>
              </div>
              <div class="vew120 mt-35 mb-30">
                <h4>3. Reglas de Contenido y Comportamiento</h4>
                <p>
                  Quisque et bibendum urna, eget consequat sapien. Integer sed
                  condimentum nibh. Integer id neque tristique, lobortis massa
                  ac, dapibus nibh. Donec nulla odio, porttitor ac rutrum eget,
                  volutpat a velit. Curabitur et enim quis diam congue dictum et
                  vitae dui. Nulla tortor orci, luctus a pretium vel, ultrices
                  porta nisl.
                </p>
              </div>

              <div class="vew120 mt-35 mb-30">
                <h4>4. Como Contactarnos</h4>
                <p>
                  Morbi lectus nunc, lacinia ut consequat a, semper vel erat.
                  Duis ut lacus nec dui sodales mattis. Mauris tellus dolor,
                  pulvinar sit amet pretium a, malesuada sed tellus. Aliquam
                  ultrices elit neque, quis lacinia ex porttitor non. Donec ac
                  iaculis turpis. Nulla lacinia enim quis orci aliquam, non
                  cursus urna pellentesque. Class aptent taciti sociosqu ad
                  litora torquent per conubia nostra, per inceptos himenaeos.
                  Phasellus in mi a nisi auctor interdum. Vivamus faucibus magna
                  sed elit interdum consequat. Vestibulum eu tortor vel ante
                  feugiat faucibus quis et urna. Quisque interdum ac enim eu
                  tempus. Fusce viverra, lectus egestas tincidunt cursus, tortor
                  sapien convallis metus, vitae auctor risus metus vel nisi.
                  Aenean dapibus bibendum mauris ut iaculis.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer-view></footer-view>
    </div>
    <!-- Body End -->
  </div>
</template>
