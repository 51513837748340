export default class Notification {
    constructor(options) {
        Object.assign(this, options)
    }

    toSend() {
        return  {
            id: this.id,
            header: this.header,
            body: this.body,
            message: this.message,
            isRead: this.isRead,
            url: this.url,
            token: this.token,
            isActive: this.isActive,
            type: this.type,
            senderId: (this.sender) ? this.sender.id : null,
            receiverId: (this.receiver) ? this.receiver.id : null
        }
    }
}
