import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ListComponent from '../../../../core/infraestructure/ListComponent';
import PostViewModel from '../../../../core/features/website/viewmodels/PostViewModel';

export default {
  extends: ListComponent,
  data() {
    return {
      item: null,
      viewModel: null,
    }
  },
  props: {},
  mounted() {},
  beforeDestroy() {},
  created() {
    this.viewModel = new PostViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      let postId = this.$route.params.id;

      this.viewModel.onSearchItem(postId);
    },
    onAllFilterChange(data, field) {
      this.onFilterChange(data, field);
    },
    mapPostImageUrl(item) {
      return (item.imageUrl) ? item.imageUrl : 'images/blog/img-1.jpg';
    },
    navigate(post) {     
      this.$router.push({
        name: "blog single",
        params:{
          id: post.id,
          title: post.title.replace(/ /g, '-').toLowerCase()
        }
      });
      location.reload();
    },
  },
  computed: {}
}