import BaseComponent from "../../../../core/infraestructure/BaseComponent";
import DashboardViewModel from '../../../../core/features/home/viewmodels/DashboardViewModel'

export default {
    extends: BaseComponent,
    data() {
        return {
            viewModel: null,
            formProcessingMessage: "",
            isFormProcessing: false,
            currentCourseStats: null,
            currentUserStats: null,
            formData: {
                email: ''
            },
            rules: {
                /* email: [
                    { required: true, message: 'Correo electrónico requerido.', trigger: 'blur,change' },
                    { type: 'email', message: 'Correo electrónico no valido.', trigger: 'blur,change' }
                ], */
            }
        };
    },
    created() {
        this.viewModel = new DashboardViewModel(this);
        this.onActive();
    },
    methods: {
        onActive() {
            this.viewModel.bindStats()
        },
        findCourseStatsResponse(response){
            this.currentCourseStats = response.data;
        },
        findUserStatsResponse(response) {
            this.currentUserStats = response.data;
        }
    },
    computed: {}
}