import _ from 'lodash';
import BaseViewModel from '../../../infraestructure/BaseViewModel';
import UrlRender from '../../../shared/helpers/UrlRender';

export default class PasswordResetViewModel extends BaseViewModel {
  constructor (view) {
    super(view)
  }

  onPasswordReset(data) {
    this.api.authentication.resetPassword(data)
      .then(response => this.view.onPasswordResetResponse(response.data))
      .catch(this.view.onPasswordResetError)
  }

  getUrlParameter(key) {
    return UrlRender.getUrlParameter(key);
  }
  
}