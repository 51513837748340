import BaseApi from '../../../infraestructure/BaseApi';

export default class ApiDashboardStudent extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    findCurrentCourses() {
        return this.client.get(`${this.endPoint}/current`)
    }

    findFinishedCourses() {
        return this.client.get(`${this.endPoint}/finished`)
    }

    findStats() {
        return this.client.get(`${this.endPoint}/stats`)
    }
}