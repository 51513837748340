import { EventBus } from '../../../../core/shared/helpers/EventHelper';
import ListComponent from '../../../../core/infraestructure/ListComponent';
import InstructorViewModel from '../../../../core/features/instructors/viewmodels/InstructorViewModel';

export default {
  extends: ListComponent,
  data() {
    return {
      modulePermissions: 'InstructorsContent-Instructors',
      item: null,
      viewModel: null,
      userStatuses: [],
      filterOptions: {
        emailFilter: '',
        fullNameFilter: '',
        status: ''
      },
      modalImport: {
        isVisible: false,
        isLoading: false,
        title: 'IMPORTAR PROFESORES',
        onUploadImport: 'onUploadImportInstructor',
        onCancelImport: 'onCancelImportInstructor'
      }
    }
  },
  mounted() {
    EventBus.$on('onSubmitedInstructor', this.onSearch);
    EventBus.$on('onUploadImportInstructor', this.onUploadImport);
    EventBus.$on('onCancelImportInstructor', this.onCancelImport);
  },
  beforeDestroy() {
    EventBus.$off('onSubmitedInstructor', this.onSearch);
    EventBus.$off('onUploadImportInstructor', this.onUploadImport);
    EventBus.$off('onCancelImportInstructor', this.onCancelImport);
  },
  created() {
    this.viewModel = new InstructorViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.onSearch();
      this.onSearchUserStatus();
    },
    onAllFilterChange(data, field) {
      this.onFilterChange(data, field);
    },
    onSearchUserStatus() {
      this.viewModel.findUserStatuses()
    },
    onSearchUserStatusesResponse(response) {
      this.userStatuses = response;
    },
    onShowImport() {
      this.viewModel.showModal(this.modalImport, true);
    },
    onUploadImport(file){
      this.viewModel.showModalLoading(this.modalImport, true);
      this.viewModel.import(file);
    },
    onCancelImport(){
      this.viewModel.showModal(this.modalImport, false);
    },
    onExportToExcel() {
      this.viewModel.exportToExcel();
    },
    onCanCreate() {
      return this.canCreate(this.modulePermissions)
    },
    onCanRead() {
      return this.canRead(this.modulePermissions)
    },
    onCanUpdate() {
      return this.canUpdate(this.modulePermissions)
    },
    onCanDelete() {
      return this.canDelete(this.modulePermissions)
    },
    mapProfileImageUrl(userProfile) {
      return (userProfile && userProfile.profileImageUrl) ? userProfile.profileImageUrl : '/images/left-imgs/img-2.jpg';
    },
    hasFacebookPage(instructor) {
      return !_.isNil(instructor.pageUrlFacebook) && !_.isEmpty(instructor.pageUrlFacebook);
    },
    hasInstagramPage(instructor) {
      return !_.isNil(instructor.pageUrlInstagram) && !_.isEmpty(instructor.pageUrlInstagram);
    },
    hasLinkedinPage(instructor) {
      return !_.isNil(instructor.pageUrlLinkedIn) && !_.isEmpty(instructor.pageUrlLinkedIn);
    },
    mapTotalCourses(instructor) {
      return (instructor.totalCourse === 1) ? `${instructor.totalCourse} Curso` : `${instructor.totalCourse} Cursos`;
    },
    mapProfileImage(userProfile) {
      return (userProfile && userProfile.profileImageUrl) ? userProfile.profileImageUrl : '/images/left-imgs/img-1.jpg'
    },
    onChangeStatus(instructor) {
      this.viewModel.doChangeStatus({
        instructorId: instructor.id,
        status: instructor.userProfile.status
      })
    },
    onChangeStatusResponse(response){
      this.onSearch();
    }
  },
  computed: {}
}