import _ from 'lodash'
import BaseViewModel from '../../../infraestructure/BaseViewModel'

export default class DashboardInstructorViewModel extends BaseViewModel {
  constructor(view) {
    super(view)
  }

  bindStats() {
    this.api.dashboardInstructors.findStats()
      .then((response) => this.view.onFindStatsResponse(response.data))
      .catch(this.view.onError);
  }
}