<script>
import ModalCourseSubjectTestQuestionComponent from '../../features/modals/ModalCourseSubjectTestQuestionComponent';

export default {
  extends: ModalCourseSubjectTestQuestionComponent,
  name: 'ModalCourseSubjectTestQuestionView',
  components: {},
};
</script>

<template>
  <div>
    <el-dialog
      title="Pregunta"
      :visible.sync="conf.isVisible"
      width="55%"
      custom-class="course-subject-modal"
      :before-close="onCancel"
      :close-on-click-modal="false"
    >
      <el-form
        :model="formData"
        :rules="rules"
        ref="formData"
        status-icon
        :label-position="'top'"
        v-loading="conf.isLoading"
      >
        <fieldset>
          <legend>Información de Pregunta</legend>
          <el-row class="row-bg" :gutter="20">
            <el-col :xs="24" :sm="20" :md="20" :lg="20" :xl="20">
              <el-form-item label="Descripción" prop="description">
                <el-input
                  v-model="formData.description"
                  size="small"
                  class="question_editor"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="Orden" prop="sort">
                <el-input-number v-model.number="formData.sort" size="small" :disabled="true"></el-input-number>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="4">
              <el-form-item label="Duración" prop="duration">
                <el-input v-model="formData.duration" size="small"></el-input>
              </el-form-item>
            </el-col>-->
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20">
            <el-col :span="24">
              <el-button
                type="primary"
                size="small"
                class="btn-primary"
                icon="el-icon-circle-plus"
                @click="onAddAnswer"
                >Agregar Respuesta</el-button
              >
            </el-col>
          </el-row>
          <el-row type="flex" class="row-bg" :gutter="20" v-loading="isAnswerLoading">
            <el-col :span="24">
              <br />
              <el-table :data="formData.getAnswers()" empty-text="No se encontraron resultados.">
                <el-table-column label="RESPUESTA" min-width="300">
                  <template slot-scope="scope">
                    <div>
                      <el-input v-model="scope.row.title" size="small" :class="'answer_editor_' + scope.$index"></el-input>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="CORRECTO" min-width="120" align="center">
                  <template slot-scope="scope">
                    <div>
                      <el-switch
                        v-model="scope.row.success"
                        active-color="#13ce66"
                        active-text="SI"
                        inactive-text="NO"
                        size="small"
                      >
                      </el-switch>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="OPCIONES" :width="90" align="center" text-align="center">
                  <template slot-scope="scope">
                    <el-button-group>
                      <el-button
                        type="default"
                        size="small"
                        icon="el-icon-delete"
                        @click="
                          onDeleteAnswer(scope.$index)
                        "
                      ></el-button>
                    </el-button-group>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </fieldset>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel" size="small" class="btn-cancel"
          >Cancelar</el-button
        >
        <el-button
          type="primary"
          @click="onSubmitForm('formData')"
          :disabled="!onCanSaveOrUpdate()"
          class="btn-primary"
          size="small"
          >Aceptar</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
